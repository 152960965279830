import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect, useSelector } from 'react-redux';
import useMedia from 'react-use/lib/useMedia';

import { SearchInput } from 'lib/forms';
import { useDebounce, useKeyPressDown } from 'lib/hooks';
import { Button, ButtonVariant, Divider, Loading } from 'lib/ui';
import { UiContainerForScrollable } from 'lib/ui/helpers';
import { store, StoreRootState } from 'store';

import { Note } from '../NoteModel';
import { NotesList } from '../NotesList/NotesList';
import { NotesSearch } from '../NotesSearch/NotesSearch';

import { NotesListContainerStyled } from './NotesListContainerStyled';
import { sortBy } from 'rambdax';

export interface NotesListContainerProps {
  onNoteClick?: Function;
}

const enhance = connect(
  ({ NoteModel, loading, UiModel }) => ({
    NoteModelStore: NoteModel,
    loading,
    UiModelStore: UiModel,
  }),
  ({ UiModel }) => ({ UiModel }),
);

const NotesListContainer: FunctionComponent<NotesListContainerProps> = enhance(
  props => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMedia('(max-width: 576px)');
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(true);
    const [selectedNoteId, setSelectedNote] = useState<number | undefined>(
      undefined,
    );
    const [notes, setNotes] = useState<Note[]>([]);
    const [allSortedNotes, setAllSortedNotes] = useState<Note[]>([]);

    const query = useSelector(
      (state: StoreRootState) => state.UiModel.notesSearchQuery,
    );

    const queryDebounced = useDebounce(query, 200);

    //  filter notes based on query value
    useEffect(() => {
      setNotes(NotesSearch(allSortedNotes, queryDebounced));
    }, [queryDebounced, allSortedNotes]);

    //  update all notes array
    useEffect(() => {
      setAllSortedNotes(
        sortBy(note =>
          note.updatedAt
            ? -new Date(note.updatedAt)
            : -new Date(note.createdAt),
        )(props.NoteModelStore.data),
      );
    }, [props.NoteModelStore.data]);

    // focus input on isFocused change
    useEffect(() => {
      if (inputRef.current && isFocused) {
        // @ts-ignore
        inputRef.current.select();
        // @ts-ignore
        inputRef.current.focus();
        // set to false to  prevent from setting select() & focus()
        // multiple times when input element is active/focused
        setFocused(false);
      }
    });

    //  set focus on F3
    useKeyPressDown(
      'F3',
      event => {
        setFocused(true);
      },
      {
        preventDefault: true,
        stopPropagation: true,
        disabled: props.UiModelStore.appContext === 'note-form',
      },
      [props.UiModelStore.appContext],
    );
    //  TODO - refactor this - move it to some generic list, maybe "SelectableList" ?
    useKeyPressDown(
      'ArrowDown',
      event => {
        const selectedNoteIndex = notes.findIndex(
          note => note.id === selectedNoteId,
        );
        if (selectedNoteIndex < notes.length - 1) {
          setSelectedNote(notes[selectedNoteIndex + 1].id);
        } else {
          setSelectedNote(notes[0].id);
        }
      },
      {
        preventDefault: true,
        stopPropagation: false,
        disabled: props.UiModelStore.appContext === 'note-form',
      },
      [notes, selectedNoteId, props.UiModelStore.appContext],
    );
    useKeyPressDown(
      'ArrowUp',
      event => {
        const selectedNoteIndex = notes.findIndex(
          note => note.id === selectedNoteId,
        );
        if (selectedNoteIndex > 0) {
          setSelectedNote(notes[selectedNoteIndex - 1].id);
        } else {
          setSelectedNote(notes[notes.length - 1].id);
        }
      },
      {
        preventDefault: true,
        stopPropagation: false,
        disabled: props.UiModelStore.appContext === 'note-form',
      },
      [notes, selectedNoteId, props.UiModelStore.appContext],
    );
    useKeyPressDown(
      'Enter',
      event => {
        let selectedNoteIndex = notes.findIndex(
          note => note.id === selectedNoteId,
        );
        if (selectedNoteIndex === -1) {
          selectedNoteIndex = 0;
        }
        if (notes[selectedNoteIndex]) {
          props.onNoteClick(notes[selectedNoteIndex]);
        }
      },
      {
        preventDefault: false,
        stopPropagation: false,
        disabled: props.UiModelStore.appContext === 'note-form',
      },
      [notes, selectedNoteId, props.UiModelStore.appContext],
    );

    const displayLoading = props.loading.effects.NoteModel.loadCollection;
    const activeNoteId = +location.pathname.replace(/\/notes\/(\d*)/, '$1');

    return (
      <NotesListContainerStyled>
        <UiContainerForScrollable>
          <div className="flex items-center justify-between space-x-2">
            <SearchInput
              ref={inputRef}
              onChange={value =>
                store.dispatch.UiModel.setNotesSearchQuery(value)
              }
              value={query}
              onBlur={() => {
                setFocused(false);
              }}
              clearOnEscape
              width={'100%'}
            />
            <Button
              variant={ButtonVariant.Primary}
              iconName={'plus'}
              onClick={() => {
                history.push('/notes/new');
              }}
            />
          </div>
          <Divider />
          {displayLoading && <Loading />}
          {!displayLoading && (
            <>
              {notes.length > 0 && (
                <NotesList
                  notes={notes}
                  onNoteClick={props.onNoteClick}
                  highlightedNoteId={selectedNoteId}
                  activeNoteId={activeNoteId}
                />
              )}
              {notes.length === 0 && queryDebounced && (
                <div>Wyszukaj coś innego</div>
              )}
              {notes.length === 0 && !queryDebounced && <div>Brak notatek</div>}
            </>
          )}
        </UiContainerForScrollable>
      </NotesListContainerStyled>
    );
  },
);

export { NotesListContainer };
