import { styled } from 'lib/ui';

export const NoteViewContainer = styled.div`
  font-size: 0.8rem;

  pre {
    padding: ${p => p.theme.space};
    font-family: ${p => p.theme.fontFamilyMonospace};
    opacity: 0.7;
    transition: opacity 250ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  blockquote {
    font-size: 1.1em;
    font-style: italic;
    color: ${p => p.theme.colorGrey};
    padding: ${p => p.theme.spaceSmall} ${p => p.theme.space}
      ${p => p.theme.spaceSmall} ${p => p.theme.spaceLargeX};
    border-left: 8px solid ${p => p.theme.colorLightGrey};
    line-height: 1.6rem;
    position: relative;
    background: ${p => p.theme.colorDirtyWhite};

    p:last-child {
      margin-bottom: 0;
    }
  }

  blockquote::before {
    font-family: Georgia, Helvetica, serif;
    content: '\\201C'; /*Unicode for Left Double Quote*/
    color: ${p => p.theme.colorGrey};
    font-size: 2.3rem;
    position: absolute;
    left: ${p => p.theme.spaceSmallX};
    top: ${p => p.theme.spaceSmall};
  }
`;
