import React from 'react';
import { formattedDate } from 'lib/ui';

export interface ChangelogProps {
  date: Date;
  title?: string;
  updates: Array<{
    name: string;
    items: string[];
  }>;
}

export const Changelog: React.FC<ChangelogProps> = props => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-2 py-3 border-b border-gray-200 sm:px-4">
        <h3 className="m-0 text-lg leading-6 font-medium text-gray-900">
          {formattedDate(props.date)}
        </h3>
      </div>
      <dl className="m-0">
        {props.updates.map((update, index) => {
          return (
            <div
              className={`${
                index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
              } px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
            >
              <dt className="text-sm leading-5 font-medium text-gray-500">
                {update.name}
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className={'list-disc m-0'}>
                  {update.items.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};
