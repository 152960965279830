import { bool } from 'prop-types';
import { useEffect } from 'react';

type ModificatorKey = 'metaKey' | 'ctrlKey' | 'altKey' | 'shiftKey';

export interface UseKeyPressDownOptions {
  preventDefault: boolean;
  stopPropagation: boolean;
  disabled: boolean;
  requiredModificator: ModificatorKey[];
}

export const useKeyPressDown = (
  targetKey,
  callback,
  options: Partial<UseKeyPressDownOptions>,
  data: any[] = [],
) => {
  // set & overwrite default options
  const _options = {
    ...{
      preventDefault: false,
      stopPropagation: false,
      disabled: false,
      requiredModificator: [],
    },
    ...options,
  };

  const matchModificators = (event): boolean => {
    return (
      _options.requiredModificator.length === 0 ||
      _options.requiredModificator.some(modificatorKey => event[modificatorKey])
    );
  };

  // define event handler
  const downHandler = event => {
    if (event.key === targetKey && matchModificators(event)) {
      callback(event);
      if (_options && _options.preventDefault) {
        event.preventDefault();
      }
      if (_options && _options.stopPropagation) {
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    }
  };

  // Add event listeners
  useEffect(() => {
    // console.log('useEffect _options.disabled', _options.disabled);
    if (!(_options && _options.disabled)) {
      window.addEventListener('keydown', downHandler);
      // Remove event listeners on cleanup
    }
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [...data, _options.disabled]);
};
