import React, { useState, useEffect } from 'react';

export function useDebounce<T>(value: T, wait = 250): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const tid = setTimeout(() => {
      setDebouncedValue(value);
    }, wait);

    return () => {
      clearTimeout(tid);
    };
  }, [value]);

  return debouncedValue;
}
