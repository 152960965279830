import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'react-use';

import { PageContainer, PageContent } from 'components';
import { ButtonSubmit, Input, Textarea } from 'lib/forms';
import { Button, ButtonVariant, Group, Spacer, styled } from 'lib/ui';
import { store, StoreRootState } from 'store';

// TODO - this pages is similar to EditPage - reuse at least some UI code

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const TextareaStyled = styled(Textarea)`
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  flex-grow: 1;
`;
const ButtonSubmitStyled = styled(ButtonSubmit)`
  width: 200px;
`;
const InputStyled = styled(Input)`
  margin: ${p => p.theme.space} ${p => p.theme.space} ${p => p.theme.spaceSmall};
  width: auto;
`;

const NoteNewPage = props => {
  const history = useHistory();
  const [title, setTitle] = useLocalStorage<string>('new-title', '');
  const [body, setBody] = useLocalStorage<string>('new-body', '');
  const [isLoading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const appContext = useSelector(
    (state: StoreRootState) => state.UiModel.appContext,
  );

  useEffect(() => {
    store.dispatch.UiModel.setAppContext('note-form');
    //  focus input
    if (inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      store.dispatch.UiModel.setAppContext(appContext);
    };
  }, []);

  return (
    <PageContainer>
      <InputStyled
        value={title}
        disabled={isLoading}
        onChange={ev => setTitle(ev.currentTarget.value)}
        ref={inputRef}
      />
      <PageContent>
        <FormContainer>
          <TextareaStyled
            value={body}
            disabled={isLoading}
            onChange={ev => {
              setBody(ev.currentTarget.value);
            }}
          />
          <Spacer />
          <Group align="space-between">
            <Button
              label="Cancel"
              disabled={isLoading}
              onClick={() => {
                setTitle('');
                setBody('');
                history.goBack();
              }}
              variant={ButtonVariant.Plain}
            />
            <ButtonSubmitStyled
              label="Save"
              loading={isLoading}
              onClick={() => {
                setLoading(true);
                store.dispatch.NoteModel.createItem({
                  data: {
                    name: title,
                    body,
                  },
                })
                  .then((data: any) => {
                    message.success(`Note added`);
                    setTitle('');
                    setBody('');
                    history.push(`/notes/${data.id}`);
                  })
                  .catch(() => {
                    setLoading(false);
                    message.error('Wystąpił błąd podczas aktualizacji');
                  });
              }}
            />
          </Group>
        </FormContainer>
      </PageContent>
    </PageContainer>
  );
};

export { NoteNewPage };
