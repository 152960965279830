import { useState, useEffect } from 'react';

import { useDebounce } from './useDebounce';
import { isClient } from '../utils/platform';

export const useDebouncedWindowSize = (
  debounceTime: number = 250,
  initialWidth = Infinity,
  initialHeight = Infinity,
) => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  });
  const stateDebounced = useDebounce(state, debounceTime);

  useEffect(() => {
    if (isClient) {
      const handler = () => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    }
    return undefined;
  }, []);

  return stateDebounced;
};
