import React from 'react';

import { styled, defaultTheme } from '../../';

const Header = styled('h1')<{
  withBorder?: boolean;
  align?: 'left' | 'center' | 'right';
}>`
  font-size: ${props => props.theme.fontSizeLargeX};
  text-align: ${props => props.align};
  color: ${props => props.theme.colorPrimaryDark};
  margin: ${props => props.theme.space};
  border-bottom: ${props =>
    props.withBorder ? `1px solid ${props.theme.colorLightGrey}` : `none`};
`;

Header.defaultProps = {
  theme: defaultTheme,
  withBorder: false,
  align: 'left',
};

export { Header };
