import React, { FunctionComponent } from 'react';
import { FormikProps, FormikValues } from 'formik';

import { Group, styled } from '../ui';

import { FormField } from './FormField';

export interface AddressFieldProps extends FormikProps<FormikValues> {
  streetFieldName: string;
  postalCodeFieldName: string;
  cityFieldName: string;
  label?: string;
  type?: string;
  vertical?: boolean;
  onEnter?: Function;
}

const PostalCodeContainer = styled.div`
  width: 200px;
`;
const AddressField: FunctionComponent<AddressFieldProps> = props => {
  return (
    <Group vertical={props.vertical}>
      <FormField
        {...props}
        name={props.streetFieldName}
        label="Ulica, nr budynku, nr lokalku"
      />
      <PostalCodeContainer>
        <FormField
          {...props}
          name={props.postalCodeFieldName}
          label="Kod pocztowy"
        />
      </PostalCodeContainer>
      <FormField {...props} name={props.cityFieldName} label="Miejscowość" />
    </Group>
  );
};

export { AddressField };
