import { styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import useMedia from 'react-use/lib/useMedia';

export const PageContainerStyled = styled.div.attrs({
  className: 'PageContainer',
})<{ isMobile: boolean }>`
  padding-top: ${p => (!p.isMobile ? '50px' : 0)};
  padding-bottom: ${p => (p.isMobile ? '50px' : 0)};
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const PageContainer: FunctionComponent = props => {
  const isMobile = useMedia('(max-width: 576px)');
  return (
    <PageContainerStyled isMobile={isMobile}>
      {props.children}
    </PageContainerStyled>
  );
};

export const PageContent = styled.div.attrs({ className: 'PageContent' })`
  padding: ${p => p.theme.space};
  overflow: auto;
  flex-grow: 1;
`;

export const PageMeta = styled.div.attrs({ className: 'PageMeta' })`
  background: ${p => p.theme.colorDirtyWhite};
  box-shadow: ${p => p.theme.boxShadow};
  padding: ${p => p.theme.spaceSmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
