import React from 'react';

export enum BadgeColor {
  default = 'default',
  blue = 'blue',
}

export enum BadgeSize {
  default = 'default',
  large = 'large',
}

export interface BadgeProps {
  color?: BadgeColor;
  size?: BadgeSize;
  rounded?: boolean;
}

export const Badge: React.FC<BadgeProps> = props => {
  const color = props.color ?? BadgeColor.default;
  const size = props.size ?? BadgeSize.default;

  const colorClasses = {
    [BadgeColor.default]: 'bg-gray-300 text-gray-800',
    [BadgeColor.blue]: 'bg-blue-200 text-blue-800',
  };
  const sizeClasses = {
    [BadgeSize.default]: 'text-xs font-medium leading-4',
    [BadgeSize.large]: 'text-sm font-medium leading-5',
  };

  return (
    <span
      className={`inline-flex items-center px-2 py-1 ${
        props.rounded ? 'rounded' : 'rounded-full'
      } ${sizeClasses[size]} ${colorClasses[color]}`}
    >
      {props.children}
    </span>
  );
};
