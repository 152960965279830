const theme = {
  //  colors
  colorPrimaryLightest: '#49DFFF',
  colorPrimaryLighter: '#00ABEE',
  colorPrimaryLight: '#08C',
  colorPrimary: '#4368c5',
  colorPrimaryDark: '#3958a5',
  colorPrimaryCompany: '#ba1b2a',
  colorSecondary: '#336971',
  backgroundHover: '#fdf5e0',
  colorGrey: '#666',
  colorLightGrey: '#d6d6d6',
  colorDirtyWhite: '#f6f6f6',
  colorWhite: '#fff',
  colorBrown: '#473300',
  colorRed: '#cf1e2f',
  colorYellow: '#ffc000',
  colorGreen: '#72ac03',
  textColor: '#222',
  toolbarBackgroundColor: '#333',
};

export { theme };
