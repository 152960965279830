import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';

import {
  Button,
  ButtonVariant,
  Toolbar,
  Group,
  Drawer,
  SimplePickerDialog,
  ButtonSize,
  Icon,
} from 'lib/ui';
import { useKeyPressDown } from 'lib/hooks';
import { NotesListContainer } from 'modules/Notes/';
import { Notek } from 'components/Notek';
import { store } from 'store';

import { LogoContainer, LogoImage, ToolbarContent } from './AppToolbar.styled';

const enhance = connect(
  ({ TagModel, GroupModel, UiModel }) => ({
    TagModelStore: TagModel,
    GroupModelStore: GroupModel,
    UiModelStore: UiModel,
  }),
  ({ TagModel, GroupModel, UiModel }) => ({ TagModel, GroupModel, UiModel }),
);

// TODO - refactor modal visibility - it already has changed

const AppToolbar = enhance(props => {
  const [modalVisible, toggleModalVisibility] = useToggle(false);
  const [tagPickerVisible, toggleTagPickerlVisibility] = useToggle(false);
  const [groupPickerVisible, toggleGroupPickerlVisibility] = useToggle(false);
  const history = useHistory();

  useKeyPressDown(
    'F3',
    () => {
      if (props.isMobile) {
        toggleModalVisibility();
      }
    },
    {},
    [],
  );

  // console.log('AppToolbar props', props);

  return (
    <Toolbar
      position={props.isMobile ? 'bottom' : 'top'}
      align={props.isMobile ? 'space-between' : 'left'}
      left={!props.isMobile ? '300px' : 0}
    >
      <LogoContainer>
        <Link to="/">
          <LogoImage
            src={`${process.env.PUBLIC_URL}/img/header_logo_icon.svg`}
            alt=""
          />
        </Link>
      </LogoContainer>

      <ToolbarContent>
        <Group>
          <Button
            label={'Tags'}
            variant={ButtonVariant.Plain}
            size={ButtonSize.Small}
            onClick={() => {
              toggleTagPickerlVisibility(true);
            }}
          />
          <Button
            label={'Groups'}
            size={ButtonSize.Small}
            variant={ButtonVariant.Plain}
            onClick={() => {
              toggleGroupPickerlVisibility(true);
            }}
          />
          {props.isMobile && (
            <Button
              label={'Notes'}
              onClick={toggleModalVisibility}
              variant={ButtonVariant.Plain}
              size={ButtonSize.Small}
            />
          )}
        </Group>

        <div className="flex items-center space-x-4">
          <div className="pointer" onClick={() => history.push('/changelog')}>
            <Icon name={'bell'} />
          </div>
          <Notek />
        </div>
      </ToolbarContent>

      {(modalVisible || !props.isMobile) && (
        <Drawer
          closable={false}
          mask={props.isMobile}
          maskClosable={true}
          displayCloseButton={props.isMobile}
          width={props.isMobile ? '70%' : 300}
          placement={props.isMobile ? 'right' : 'left'}
          visible
          onClose={() => toggleModalVisibility(false)}
          bodyStyle={{ padding: '0px', height: '100%', overflow: 'hidden' }}
          style={{ height: '100%' }}
        >
          <NotesListContainer
            onNoteClick={note => {
              history.push(`/notes/${note.id}`);
              toggleModalVisibility(false);
            }}
          />
        </Drawer>
      )}

      {tagPickerVisible && (
        <SimplePickerDialog
          items={props.TagModelStore.data}
          multiple
          onSave={(itemIds, items) => {
            store.dispatch.UiModel.setNotesSearchQuery(
              `#${items.map(item => item.name).join(' #')}`,
            );
            toggleTagPickerlVisibility(false);
          }}
          onCancel={() => {
            toggleTagPickerlVisibility(false);
          }}
          onNewItem={(name, setLoading) => {
            props.TagModel.createItem({ data: { name } })
              .then(() => {
                props.TagModel.loadCollection();
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      )}
      {groupPickerVisible && (
        <SimplePickerDialog
          items={props.GroupModelStore.data}
          multiple={false}
          onSave={(itemId, item) => {
            toggleGroupPickerlVisibility(false);
            props.UiModel.setNotesSearchQuery(`$${item.name}`);
          }}
          onCancel={() => {
            toggleGroupPickerlVisibility(false);
          }}
          onNewItem={(name, setLoading) => {
            props.GroupModel.createItem({ data: { name } })
              .then(() => {
                props.GroupModel.loadCollection();
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      )}
    </Toolbar>
  );
});
export { AppToolbar };
