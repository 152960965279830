import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Overlay,
  OverlayProps,
} from '../../';
import { useKeyPressDown } from '../../../hooks';

import styles from './Modal.module.scss';

export interface ModalProps extends OverlayProps {
  onClose?: Function;
  onEsc?: Function;
  closeOnEsc?: boolean;
  closable?: boolean;
  visible?: boolean;
  full?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  position?: 'top' | 'center' | 'bottom';
}

const Modal: FunctionComponent<ModalProps> = props => {
  useKeyPressDown(
    'Escape',
    () => {
      if (!props.visible) {
        return;
      }
      if (props.onEsc) {
        props.onEsc();
      }
      if (props.closable && props.closeOnEsc && props.onClose) {
        props.onClose();
      }
    },
    {},
    [
      props.visible,
      props.closable,
      props.onClose,
      props.onEsc,
      props.closeOnEsc,
    ],
  );

  if (!props.visible) {
    return null;
  }

  return (
    <Overlay ms={props.ms} color={props.color} verticalAlign={props.position}>
      <div
        className={classnames(styles.ModalContainer, {
          [styles.full]: props.full,
        })}
      >
        <div className={styles.ModalCloseButton}>
          <Button
            iconName="times"
            size={ButtonSize.Large}
            onClick={() => {
              if (props.closable && props.onClose) {
                props.onClose();
              }
            }}
            variant={ButtonVariant.Plain}
          />
        </div>
        <div className={styles.Modal}>
          {props.header && (
            <div className={styles.ModalHeader}>{props.header}</div>
          )}
          <div className={styles.ModalBody}>{props.children}</div>
          {props.footer && (
            <div className={styles.ModalFooter}>{props.footer}</div>
          )}
        </div>
      </div>
    </Overlay>
  );
};

Modal.defaultProps = {
  visible: false,
  full: false,
  closeOnEsc: true,
  closable: true,
  onClose: () => {},
  onEsc: () => {},
  position: 'center',
};

export { Modal };
