export const getErrorFromResponse: (
  responseWithError: any,
) => { [key: string]: string } = (responseWithError: any) => {
  const knownStatues = [500, 400];

  if (
    responseWithError.config &&
    responseWithError.data &&
    knownStatues.includes(responseWithError.status)
  ) {
    return responseWithError.data;
  }

  if (responseWithError.config && responseWithError.response) {
    const { response } = responseWithError;
    if (knownStatues.includes(response.status) && response.data) {
      return response.data;
    }
  }

  return {
    nonFieldErrors: 'Wystąpił nieoczekiwany błąd',
  };
};
