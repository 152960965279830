import { AppToolbar } from 'components';
import { Events } from 'lib/services';
import { styled } from 'lib/ui';

import {
  AuthRoute,
  LoginPage,
  RegisterPage,
  ResetPasswordConfirmPage,
  ResetPasswordPage,
} from 'modules/Auth';
import { HomePage, LoadingPage, NoteNewPage, NotePage, Page404 } from 'pages';
import React, { useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMedia } from 'react-use';
import { ChangelogPage } from './pages/ChangelogPage/ChangelogPage';

import { AppShortcuts } from './AppShortcuts';

const mapState = state => ({
  initialized: state.AuthModel.initialized,
  isLoggedIn: state.AuthModel.isLoggedIn,
  loading: state.loading.effects.AuthModel.getUserInfo,
});

const mapDispatch = ({
  AuthModel: { getUserInfo },
  NoteModel,
  TagModel,
  GroupModel,
  UiModel,
}) => ({
  getUserInfo,
  NoteModel,
  TagModel,
  GroupModel,
  UiModel,
});
const enhance = connect(mapState, mapDispatch);

const AppContainer = styled.div.attrs({ className: 'AppContainer' })<{
  isMobile: boolean;
  isLoggedIn: boolean;
}>`
  margin: 0 auto;
  text-align: left;
  height: 100%;
  overflow: auto;
  margin-left: ${p => (!p.isMobile && p.isLoggedIn ? '300px' : 'auto')};
`;

const App = ({
  initialized,
  getUserInfo,
  isLoggedIn,
  NoteModel,
  TagModel,
  GroupModel,
  UiModel,
}) => {
  const isMobile = useMedia('(max-width: 576px)');
  const token = localStorage.getItem('notsy_token');

  const loadNotes = () => {
    NoteModel.loadCollection({
      params: {
        ordering: '-updated_at',
        fields: [
          'id',
          'name',
          'owner',
          'body',
          'is_public',
          'public_url',
          'created_at',
          'updated_at',
          'group',
          'tags',
        ].join(','),
      },
    });
  };

  useEffect(() => {
    if (token) {
      getUserInfo();
      loadNotes();
      TagModel.loadCollection();
      GroupModel.loadCollection();
    }
  }, [token]);

  useEffect(() => {
    UiModel.setAppContext('app');
  }, []);

  Events.on('Model.NoteModel.createItem.success', loadNotes);

  if (!initialized && token) {
    return <LoadingPage />;
  }

  return (
    <AppContainer isMobile={isMobile} isLoggedIn={isLoggedIn}>
      <Router>
        <>
          <AppShortcuts />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/changelog" exact component={ChangelogPage} />
            <Route path="/register/:token" exact component={RegisterPage} />
            <Route path="/reset-password" exact component={ResetPasswordPage} />
            <Route
              path="/reset-password/:uid/:token"
              component={ResetPasswordConfirmPage}
            />
            <AuthRoute path="/notes/new" component={NoteNewPage} />
            <AuthRoute path="/notes/:id" component={NotePage} />
            <Route path="*" component={Page404} />
          </Switch>
          {isLoggedIn && <AppToolbar isMobile={isMobile} />}
        </>
      </Router>
    </AppContainer>
  );
};

export default enhance(App);
