// const languageId = 'pl-PL';
const languageId = 'en-UK';

export const ensureDateObject = date => {
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date);
  }
  return date;
};

export const formattedDate = date =>
  new Intl.DateTimeFormat(languageId, {
    formatMatcher: 'basic',
    month: 'long',
    day: '2-digit',
    weekday: 'long',
    year: 'numeric',
  }).format(ensureDateObject(date));

export const formattedShortDate = date =>
  new Intl.DateTimeFormat(languageId, {
    formatMatcher: 'basic',
    month: '2-digit',
    day: '2-digit',
  }).format(ensureDateObject(date));

export const formattedShortDateWithWeekDayName = date =>
  new Intl.DateTimeFormat(languageId, {
    formatMatcher: 'basic',
    day: '2-digit',
    weekday: 'long',
  }).format(ensureDateObject(date));

export const formattedTime = date =>
  new Intl.DateTimeFormat(languageId, {
    formatMatcher: 'basic',
    hour: '2-digit',
    minute: '2-digit',
  }).format(ensureDateObject(date));
