import { Tooltip as TooltipAnt } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

export interface TooltipProps {
  text?: string | ReactNode;
  mouseEnterDelay?: number; // in seconds
  children?: any;
  visible?: boolean;
}

const TooltipDefaultProps = {
  mouseEnterDelay: 0.25,
};

const Tooltip: FunctionComponent<TooltipProps> = props => {
  // set initial visible state
  const [visible, setVisible] = useState(!!props.visible);
  // update state if props changes
  useEffect(() => {
    setVisible(!!props.visible);
  }, [props.visible]);

  return (
    <TooltipAnt
      title={props.text}
      mouseEnterDelay={props.mouseEnterDelay}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <span>{props.children}</span>
    </TooltipAnt>
  );
};

Tooltip.defaultProps = TooltipDefaultProps;

export { Tooltip };
