import { message } from 'antd';

import { ButtonSubmit } from 'lib/forms';
import { useKeyPressDown } from 'lib/hooks';
import { Button, ButtonVariant, Group, Spacer, styled } from 'lib/ui';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Note } from './NoteModel';

export interface NoteEditProps {
  children?: ReactNode;
  note: Note;
  onCancel: Function;
  onUpdate: Function;
}

const enhance = compose(
  connect(
    ({ NoteModel, loading }) => ({
      NoteModelStore: NoteModel,
      loading,
    }),
    ({ NoteModel }) => ({
      NoteModel,
    }),
  ),
);
const NoteEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const TextareaStyled = styled.textarea`
  display: block;
  padding: ${props => props.theme.spaceSmallX}
    ${props => props.theme.spaceSmall};
  max-width: 100%;
  border: 1px solid ${props => props.theme.colorGrey};
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colorWhite};
  transition: all 0.25s ease-in-out;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  flex-grow: 1;

  :focus {
    border-color: ${props => props.theme.colorPrimary};
  }
`;
const ButtonSubmitStyled = styled(ButtonSubmit)`
  width: 200px;
`;

export const NoteEdit: FunctionComponent<NoteEditProps> = enhance(props => {
  const [noteBody, setBody] = useState(props.note.body);
  const [isLoading, setLoading] = useState(false);
  const editorRef = useRef<HTMLTextAreaElement | null>(null);

  const handleSave = useCallback(() => {
    setLoading(true);
    props.NoteModel.updateItem({
      id: props.note.id,
      data: {
        body: noteBody,
      },
    })
      .then(() => {
        message.success(`Note updated`);
        props.onUpdate();
      })
      .catch(() => {
        setLoading(false);
        message.error('Wystąpił błąd podczas aktualizacji');
      });
  }, [noteBody, props]);

  //  close edit mode on Escape
  useKeyPressDown(
    'Escape',
    props.onCancel,
    {
      stopPropagation: true,
    },
    [],
  );
  //  save on cmd + enter or ctrl + enter
  useKeyPressDown(
    'Enter',
    handleSave,
    {
      preventDefault: true,
      stopPropagation: true,
      requiredModificator: ['metaKey', 'ctrlKey'],
    },
    [handleSave],
  );

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, []);

  return (
    <NoteEditContainer>
      <TextareaStyled
        value={noteBody}
        disabled={isLoading}
        onChange={ev => setBody(ev.currentTarget.value)}
        ref={editorRef}
      />
      <Spacer />
      <Group align="space-between">
        <Button
          label="Cancel"
          disabled={isLoading}
          onClick={props.onCancel}
          variant={ButtonVariant.Plain}
        />
        <ButtonSubmitStyled
          label="Save"
          loading={isLoading}
          onClick={handleSave}
        />
      </Group>
    </NoteEditContainer>
  );
});
