export type AppContext = 'app' | 'note-view' | 'note-form' | 'modal';

const UiModelInitialState = {
  notePageMetaCollapsed: false,
  notesSearchQuery: '',
  appContext: 'app', // AppContext
};

export const UiModel = {
  state: UiModelInitialState,
  reducers: {
    'AuthModel/logout': (state, payload) => {
      return UiModelInitialState;
    },
    toggleNotePageMeta: (state, payload?: boolean) => {
      return {
        ...state,
        notePageMetaCollapsed:
          typeof payload === 'undefined'
            ? !state.notePageMetaCollapsed
            : payload,
      };
    },
    setNotesSearchQuery: (state, payload?: string) => {
      return {
        ...state,
        notesSearchQuery: typeof payload === 'undefined' ? '' : payload,
      };
    },
    setAppContext: (state, context: AppContext) => {
      return {
        ...state,
        appContext: context,
      };
    },
  },
};
