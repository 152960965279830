import React, { FunctionComponent } from 'react';

import { Icon, IconFa, IconSize, styled } from '../..';

const DimmerLoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.space};
  background: rgba(0, 0, 0, 0.1);
`;

export interface DimmerLoadingProps {
  visible: boolean;
}

const DimmerLoading: FunctionComponent<DimmerLoadingProps> = props => {
  if (!props.visible) {
    return null;
  }
  return (
    <DimmerLoadingContainer>
      <Icon name="spinner" fa={IconFa.Fal} spin size={IconSize.Large} />
    </DimmerLoadingContainer>
  );
};

export { DimmerLoading };
