import { Button, ButtonVariant, Group } from 'lib/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import connect from 'react-redux/es/connect/connect';

import { PageContainer, PageContent, PageMeta } from 'components';
import { Stat } from 'components/Stat';
import { isNoteUpdatedInLast7Days } from 'modules/Notes/NoteModel.utils';
import config from 'config';
import { StoreRootState } from 'src/store';

import { WelcomePage } from './WelcomePage/WelcomePage';
import { HelpShortcutsBox } from './HelpShortcutsBox';

const enhance = connect(
  ({ AuthModel: { isLoggedIn } }) => ({
    isLoggedIn,
  }),
  ({ AuthModel: { logout } }) => ({
    logout,
  }),
);

const HomePage = enhance(props => {
  const notesCounter = useSelector(
    (state: StoreRootState) => state.NoteModel.dataIds.length,
  );
  const notesUpdatedCounter = useSelector(
    (state: StoreRootState) =>
      state.NoteModel.data.filter(isNoteUpdatedInLast7Days).length,
  );

  if (!props.isLoggedIn) {
    return <WelcomePage />;
  }

  return (
    <PageContainer>
      <PageMeta>
        <div className="flex flex-col space-y-4 w-full pb-4">
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              {config.appVersion && config.appVersion !== 'undefined' && (
                <div className="text-gray-500">{config.appVersion}</div>
              )}
              {config.appGitVersion && config.appGitVersion !== 'undefined' && (
                <div className="text-gray-500">{config.appGitVersion}</div>
              )}
              {/*<Button*/}
              {/*  onClick={() => {*/}
              {/*    alert('not implemented yet');*/}
              {/*  }}*/}
              {/*  variant={ButtonVariant.Plain}*/}
              {/*  label="Deleted notes"*/}
              {/*/>*/}
            </div>
            {props.isLoggedIn && (
              <Button
                onClick={props.logout}
                variant={ButtonVariant.Default}
                label="Logout"
              />
            )}
          </div>

          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
            <Stat label={'Notes'} value={notesCounter ? notesCounter : '?'} />
            <Stat
              label={'Updated in last 7 days'}
              value={notesUpdatedCounter}
            />
          </div>
        </div>
      </PageMeta>

      <PageContent>
        <div className="flex flex-col space-y-6">
          <HelpShortcutsBox />
        </div>
      </PageContent>
    </PageContainer>
  );
});

export { HomePage };
