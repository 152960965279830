import { styled } from '../../..';

const AgGridTableContainer = styled('div').attrs({
  className: 'ag-theme-balham',
})`
  width: 100%;
  margin: ${p => p.theme.spaceLarge} 0;
  overflow: hidden;
  flex-grow: 1;
`;

export { AgGridTableContainer };
