import React from 'react';
import classnames from 'classnames';

import styles from './Group.module.scss';

export interface GroupProps {
  children?: any;
  align?: 'right' | 'center' | 'space-between';
  vertical?: boolean;
  verticalAlign?: 'top' | 'center' | 'bottom';
  smallSpacing?: boolean;
  width?: string | number;
  wrap?: boolean;
}

const Group = (props: GroupProps) => (
  <div
    className={classnames(styles.Group, {
      [styles.alignRight]: props.align === 'right',
      [styles.alignCenter]: props.align === 'center',
      [styles.alignSpaceBetween]: props.align === 'space-between',
      [styles.vertical]: props.vertical,
      [styles.wrap]: props.wrap,
      [styles.smallSpacing]: props.smallSpacing,
      [styles.verticalAlignTop]: props.verticalAlign === 'top',
      [styles.verticalAlignCenter]: props.verticalAlign === 'center',
      [styles.verticalAlignBottom]: props.verticalAlign === 'bottom',
    })}
    style={{
      width: props.width ? props.width : '100%',
    }}
  >
    {props.children}
  </div>
);

export { Group };
