import React, { FunctionComponent, useEffect, useState } from 'react';
import { ReactSortable, SortableEvent } from 'react-sortablejs'

import { styled, Button, ButtonVariant, Group, Modal, Icon } from '../';
import { UiContainerForScrollable } from '../helpers';

export interface SimpleSortableDialogProps {
  onCancel: () => void;
  onSave: Function;
  items: Array<{
    id: number | string;
    name: string;
  }>;
  title?: string;
  cancelText?: string;
  okText?: string;
  renderItem?: Function;
}

const SimpleSortableDialogDefaultProps = {};

const ItemContainer = styled.div.attrs({ className: 'ItemContainer ' })`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${p => p.theme.spaceSmallX} ${p => p.theme.spaceSmall}
  cursor: pointer;
`;
const ItemIconContainer = styled.div.attrs({ className: 'ItemIconContainer ' })`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ItemName = styled.div.attrs({ className: 'ItemName ' })`
  text-align: left;
`;
const SimplePickerTitle = styled.div.attrs({ className: 'SimplePickerTitle ' })`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SimpleSortableDialog: FunctionComponent<
  SimpleSortableDialogProps
> = props => {
  //  search query
  const [query, setQuery] = useState('');
  const [items, setItems] = useState(props.items);

  //  loading state - set
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItems(items);
  }, [props.items]);

  console.log('SimpleSortableDialog.props', props);

  return (
    <Modal
      visible
      header={
        <SimplePickerTitle>
          <span>{props.title || 'Change order'}</span>
        </SimplePickerTitle>
      }
      onClose={props.onCancel}
      position="top"
      footer={
        <Group align="right">
          <Button
            disabled={loading}
            onClick={props.onCancel}
            label={props.cancelText || 'Cancel'}
          />
          <Button
            loading={loading}
            variant={ButtonVariant.Primary}
            onClick={() => {
              setLoading(true);
              props.onSave(items);
            }}
            label={props.okText || 'Ok'}
          />
        </Group>
      }
    >
      <UiContainerForScrollable height={'400px'}>
        <ReactSortable
          tag="div"
          list={items}
          setList={setItems}
          // onChange={(ids: SortableEvent, sortable, evt) => {
          //   setItems(
          //     ids.map((id, index) => {
          //       const item = items.find(_item => _item.id === parseInt(id, 10));
          //       return {
          //         ...item,
          //         position: index,
          //       };
          //     }),
          //   );
          // }}
        >
          {items
            .filter(
              item =>
                !query ||
                item.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
            )
            .map(item => (
              <div data-id={item.id} key={item.id}>
                <ItemContainer>
                  <ItemIconContainer>
                    <Icon name="grip-lines" />
                  </ItemIconContainer>
                  {props.renderItem ? (
                    props.renderItem(item)
                  ) : (
                    <ItemName>{item.name}</ItemName>
                  )}
                </ItemContainer>
              </div>
            ))}
        </ReactSortable>
      </UiContainerForScrollable>
    </Modal>
  );
};

SimpleSortableDialog.defaultProps = SimpleSortableDialogDefaultProps;

export { SimpleSortableDialog };
