import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { useDebouncedWindowSize } from 'lib/hooks';

import { AgGridTableContainer } from './AgGridTableContainer/AgGridTableContainer';

export interface AgGridProps extends AgGridReactProps {}

const AgGridDefaultProps = {};

export const AgGridTable: FunctionComponent<AgGridProps> = props => {
  // const gridPropsRef = useRef<AgGrid.ColumnApi>(null);
  const gridPropsRef = useRef<any>(null);
  const { width, height } = useDebouncedWindowSize(100);

  useEffect(() => {
    if (gridPropsRef.current) {
      gridPropsRef.current.api.sizeColumnsToFit();
    }
  }, [width, height, props.rowData, props.columnDefs]);

  return (
    <AgGridTableContainer>
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ resizable: true }}
        onGridReady={gridProps => {
          gridPropsRef.current = gridProps;
          setTimeout(() => {
            gridPropsRef.current.api.sizeColumnsToFit();
          }, 100);
        }}
        onFirstDataRendered={params => {
          setTimeout(() => {
            params.api.sizeColumnsToFit();
          }, 100);
        }}
        rowHeight={30}
        {...props}
      />
    </AgGridTableContainer>
  );
};

AgGridTable.defaultProps = AgGridDefaultProps;
