import React from 'react';
import { styled } from 'lib/ui';

export interface IconArrowOpenNoteProps {}

const DotIconArrowOpenNote = styled.div.attrs({
  className: 'dotIconArrowOpenNote bg-gray-300',
})`
  width: 1rem;
  height: 1rem;
`;

export const IconArrowOpenNote: React.FC<IconArrowOpenNoteProps> = props => {
  return (
    <div>
      <DotIconArrowOpenNote></DotIconArrowOpenNote>
    </div>
  );
};
