import React, { useEffect, useState } from 'react';

import { useDebounce } from '../../hooks';
import { Icon } from '../../ui';
import { Input } from '..';

import { SearchInputContainer } from './SearchInputContainer';
import { SearchInputClearButtonContainer } from './SearchInputClearButtonContainer';

export interface SearchInputProps {
  value?: string;
  placeholder?: string;
  debounceTime?: number;
  width?: number | string;
  onChange: (query: string) => void;
  onBlur?: () => void;
  clearOnEscape?: boolean;
}

const SearchInputDefaultProps = {
  debounceTime: 250,
  value: '',
  placeholder: '',
  clearOnEscape: false,
};

const SearchInput = React.forwardRef((props: SearchInputProps, ref) => {
  // const [innerValue, setInnerValue] = useState(props.value || '');
  // const innerValueDebounced = useDebounce(innerValue, props.debounceTime);

  const innerValue = props.value || '';

  // useEffect(() => {
  //   if (props.debounceTime) {
  //     props.onChange(innerValueDebounced);
  //   }
  // }, [innerValueDebounced]);
  //
  // useEffect(() => {
  //   if (!props.debounceTime) {
  //     props.onChange(innerValue);
  //   }
  // }, [innerValue]);

  // useEffect(() => {
  //   setInnerValue(props.value ?? '')
  // }, [props.value]);

  return (
    <SearchInputContainer>
      <Input
        value={props.value}
        // @ts-ignore
        ref={ref}
        onChange={ev => {
          props.onChange(ev.target.value);
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        onKeyDown={event => {
          if (event.key === 'Escape' && props.clearOnEscape) {
            props.onChange('');
          }
        }}
        placeholder={props.placeholder}
        width={props.width}
      />
      {innerValue.length > 0 && (
        <SearchInputClearButtonContainer
          onClick={() => {
            props.onChange('');
          }}
        >
          <Icon name="times" />
        </SearchInputClearButtonContainer>
      )}
    </SearchInputContainer>
  );
});

SearchInput.defaultProps = SearchInputDefaultProps;

export { SearchInput };
