import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { Formik } from 'formik';

import { Divider, DividerTextOrientation, Group } from 'lib/ui';
import { path } from 'rambdax';
import { Events } from 'services';
import { ButtonSubmit, FormError, FormField } from 'lib/forms';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.register,
  }),
  state => ({ AuthModel: { register, getUserInfo, clearError } }) => ({
    register,
    getUserInfo,
    clearError,
  }),
);

const RegisterPage = enhance(props => {
  const redirectToLogin = useRef<boolean>();

  useLifecycles(
    () => {
      Events.on('AuthModel.register.success', () => {
        redirectToLogin.current = true;
        console.warn('AuthModel.register.success');
      });
    },
    () => {
      Events.off('AuthModel.register.success', true);
      props.clearError();
    },
  );

  // get user info
  useEffect(() => {
    if (props.auth.token) {
      props.getUserInfo();
    }
  }, [props.auth.token]);

  // get invitation token from url
  const { token } = props.match.params;
  // check token on change
  useEffect(() => {
    console.warn('token', token);
    // todo - request to API to check if token exists in DB
  }, [token]);

  const { loading } = props;

  if (redirectToLogin.current) {
    // @todo - display some message
    return <Redirect to={'/login'} />;
  }

  const isLoggedIn = path('auth.isLoggedIn', props);
  if (isLoggedIn) {
    return <Redirect to={props.auth.nextUrl ? props.auth.nextUrl : '/'} />;
  }

  if (!token) {
    return null;
  }

  return (
    <AuthBasePage title="Rejestracja">
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
          invitationToken: token,
        }}
        validate={values => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Enter your e-mail';
          }
          if (!values.username) {
            errors.username = 'Enter your username';
          }
          if (!values.password) {
            errors.password = 'Enter password';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          props.register(values).then(() => {
            bag.setSubmitting(false);
          });
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField
                    name="email"
                    placeholder=""
                    label="E-mail address"
                    {...formikProps}
                  />
                </Group>
                <Group>
                  <FormField
                    name="username"
                    placeholder=""
                    label="Username"
                    {...formikProps}
                  />
                </Group>
                <Group>
                  <FormField
                    name="password"
                    placeholder=""
                    label="Password"
                    type="password"
                    {...formikProps}
                  />
                </Group>
              </Group>
              <div className={'mt-4'}>
                <ButtonSubmit
                  disabled={
                    formikProps.isSubmitting || !formikProps.isValid || loading
                  }
                  loading={loading || formikProps.isSubmitting}
                  label="Register"
                  onClick={formikProps.handleSubmit}
                  iconName="sign-in"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <Divider
        text="already have an account?"
        textOrientation={DividerTextOrientation.Center}
      />
      <Group align="center">
        <Link to="/login">Login</Link>
      </Group>
    </AuthBasePage>
  );
});

export { RegisterPage };
