import { useKeyPressDown } from 'lib/hooks';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';

export interface AppShortcutsProps {}

export const AppShortcuts: FunctionComponent<AppShortcutsProps> = props => {
  const history = useHistory();
  useKeyPressDown(
    'F4',
    () => {
      history.push('/notes/new');
    },
    {},
    [],
  );

  return null;
};
