import React, { FunctionComponent } from 'react';

import { Box, Spacer, styled } from 'lib/ui';

export interface HelpShortcutsBoxProps {}

const HelpShortcutsBoxContainer = styled.div.attrs({
  className: 'HelpShortcutsBoxContainer',
})`
  h3 {
    font-size: ${p => p.theme.fontSizeLarge};
    font-weight: ${p => p.theme.fontWeightThin};
    color: ${p => p.theme.colorPrimaryDark};
  }
  dl {
    display: grid;
    grid-template-columns: 100px auto;
    grid-row-gap: ${p => p.theme.spaceSmallX};
    margin-top: ${p => p.theme.space};
  }
  dt {
    font-weight: ${p => p.theme.fontWeightBold};
    text-align: left;
    margin-right: ${p => p.theme.spaceSmall};
  }
  dd {
    opacity: 0.8;
  }
`;

export const HelpShortcutsBox: FunctionComponent<
  HelpShortcutsBoxProps
> = props => {
  return (
    <HelpShortcutsBoxContainer>
      <h2>Help - shortcuts</h2>
      <Spacer />

      <Box noMargin>
        <h3>Global</h3>
        <dl>
          <dt>F3</dt>
          <dd>Set focus in notes search input.</dd>
        </dl>
        <dl>
          <dt>F4</dt>
          <dd>Open new note form.</dd>
        </dl>

        <h3>Note Search Input</h3>
        <dl>
          <dt>Escape</dt>
          <dd>Clear search and display all notes.</dd>
          <dt>Arrow down</dt>
          <dd>
            Highlight next note on the list (first if there is no highlighted
            note)
          </dd>
          <dt>Arrow up</dt>
          <dd>
            Highlight previous note on the list (last if there is no highlighted
            note)
          </dd>
          <dt>Enter</dt>
          <dd>
            Open highlighted note or first one if there is no highlighted note
          </dd>
        </dl>

        <h3>Note View</h3>
        <dl>
          <dt>F2</dt>
          <dd>Start editing note.</dd>
        </dl>

        <h3>Note Form</h3>
        <dl>
          <dt>Escape</dt>
          <dd>Close note form and discard any changes.</dd>
          <dt>
            CMD + Enter <br /> CTRL + Enter
          </dt>
          <dd>Save note and close form.</dd>
        </dl>

        <h3>Note Title Form</h3>
        <dl>
          <dt>Escape</dt>
          <dd>Close form and discard any changes.</dd>
          <dt>Enter</dt>
          <dd>Save note's title and close form.</dd>
        </dl>
      </Box>
    </HelpShortcutsBoxContainer>
  );
};
