import { Divider } from 'lib/ui';

import { UiScrollableList } from 'lib/ui/helpers';
import React, { FunctionComponent } from 'react';

import { NotesListItem } from '../NotesListItem/NotesListItem';

export interface NotesListProps {
  notes?: any[]; // Todo Note Type
  onNoteClick?: Function;
  highlightedNoteId?: string | number;
  activeNoteId?: number;
}

const NotesListDefaultProps = {
  notes: [],
  onNoteClick: () => {},
};

const NotesList: FunctionComponent<NotesListProps> = ({
  notes,
  highlightedNoteId,
  activeNoteId,
  onNoteClick,
}) => {
  return (
    <>
      <UiScrollableList withoutRightPadding>
        {notes &&
          notes.length > 0 &&
          notes.map((note, index) => {
            return (
              <React.Fragment key={note.id}>
                <NotesListItem
                  note={note}
                  active={note.id === activeNoteId}
                  highlighted={note.id === highlightedNoteId}
                  onClick={() => {
                    if (onNoteClick) {
                      onNoteClick(note);
                    }
                  }}
                />
                {index < notes.length && <Divider noMargin color="#ececec" />}
              </React.Fragment>
            );
          })}
      </UiScrollableList>
    </>
  );
};

NotesList.defaultProps = NotesListDefaultProps;

export { NotesList };
