import React, { FunctionComponent, ReactNode } from 'react';
import { pipe } from 'rambdax';

import { styled } from '../..';

/**
 Example of priceWithCurrency
 {
    "amount": 45000,
    "currency": {
      "symbol": "€",
      "rounding": 0,
      "name": "Euro",
      "iso": "EUR",
      "decimalDigits": 2,
      "decimalSeparator": ",",
      "spaceBetweenAmountAndSymbol": true,
      "symbolOnLeft": false,
      "thousandsSeparator": " ",
    },
  }
 */

export interface Currency {
  symbol: string;
  name: string;
  iso: string;
  rounding: number;
  decimalDigits: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  spaceBetweenAmountAndSymbol: boolean;
  prefixWithSymbol: boolean;
}

export interface PriceProps {
  children?: ReactNode;
  amount: number; // price should be store as whole numbers in database
  currency?: Currency;
}

const PriceDefaultProps = {
  currency: {
    symbol: 'zł',
    name: 'PLN',
    iso: 'PLN',
    rounding: 100,
    decimalDigits: 2,
    decimalSeparator: ',',
    thousandsSeparator: ' ',
    spaceBetweenAmountAndSymbol: true,
    prefixWithSymbol: false,
  },
};

const PriceContainer = styled.div``;

// todo - finish formatting according to all options
const Price: FunctionComponent<PriceProps> = ({ currency, amount }) => {
  if (!currency) {
    return null;
  }

  const formattedPrice = pipe(
    (_amount: number) => _amount / currency.rounding,
    roundedAmount => {
      return roundedAmount.toFixed(currency.decimalDigits);
    },
    amountWithDecimals => {
      return amountWithDecimals.replace('.', currency.decimalSeparator);
    },
    amountWithDecimals => {
      return `${
        currency.prefixWithSymbol ? currency.symbol : ''
      }${amountWithDecimals}${
        !currency.prefixWithSymbol ? currency.symbol : ''
      }`;
    },
    priceWithSymbol => {
      return currency.spaceBetweenAmountAndSymbol
        ? priceWithSymbol.replace(
            currency.symbol,
            `${!currency.prefixWithSymbol ? ' ' : ''}${currency.symbol}${
              currency.prefixWithSymbol ? ' ' : ''
            }`,
          )
        : priceWithSymbol;
    },
  )(amount);

  return <PriceContainer>{formattedPrice}</PriceContainer>;
};

Price.defaultProps = PriceDefaultProps;

export { Price };
