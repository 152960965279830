import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { styled } from '../..';

import styles from './Icon.module.scss';

export enum IconColor {
  Default = 'default',
  Red = 'red',
  Green = 'green',
  Grey = 'grey',
  LightGrey = 'lightgrey',
  Yellow = 'yellow',
}

export enum IconFa {
  Fas = 'fas',
  Far = 'far',
  Fal = 'fal',
  Fab = 'fab',
  Fad = 'fad',
}

export enum IconSize {
  SmallX = 'small-x',
  Small = 'small',
  Default = 'default',
  Large = 'large',
  LargeX = 'large-x',
  LargeXX = 'large-xx',
}

export interface IconProps {
  name: string;
  fa?: IconFa;
  spin?: boolean;
  inverse?: boolean;
  color?: IconColor;
  size?: IconSize;
}

const IconDefaultProps = {
  fa: IconFa.Fal,
  spin: false,
  inverse: false,
  name: 'chevron-right',
  color: IconColor.Default,
  size: IconSize.Default,
};

const IStyled = styled.i`
  line-height: inherit;
`;

const Icon: FunctionComponent<IconProps> = ({
  fa,
  spin,
  inverse,
  name,
  color,
  size,
}: IconProps) => {
  const styleColor = color ? styles[`color-${color}`] : null;
  const styleSize = size ? styles[`size-${size}`] : null;
  const iconName = name.slice(0, 3) === 'fa-' ? name : `fa-${name}`;

  if (fa) {
    return (
      <IStyled
        className={classNames(`${fa} ${iconName}`, styleColor, styleSize, {
          'fa-spin': spin,
          'fa-inverse': inverse,
        })}
        aria-hidden="true"
      />
    );
  }
  return null;
};

Icon.defaultProps = IconDefaultProps;

export { Icon };
