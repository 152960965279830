const createSourceElement = () => {
  const el = document.createElement('div');
  el.id = 'clipboard-copy-source';
  el.style.opacity = '0';
  el.style.position = 'absolute';
  el.style.right = '-99999px';
  document.body.appendChild(el);
  return el;
};

export const copyFromElement = element => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  if (!selection) {
    return null;
  }
  selection.removeAllRanges();
  selection.addRange(range);

  selection.toString();
  document.execCommand('copy');
};

export const copyFromText = text => {
  let element = document.getElementById('clipboard-copy-source');
  if (!element) {
    element = createSourceElement();
  }
  element.innerText = text;
  document.createRange().selectNodeContents(element);
  copyFromElement(element);
};
