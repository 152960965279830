import { HttpApi, Events } from 'services';
import { getErrorFromResponse } from 'lib/services/SimpleRestModel/getErrorFromResponse';

const defaultEmptyState = {
  isLoggedIn: false,
  user: null,
  token: null,
  nextUrl: '',
};

export const AuthModel = {
  state: {
    initialized: false,
    ...defaultEmptyState,
  },
  selectors: (slice, createSelector, hasProps) => ({
    hasRole: hasProps((state, role) => {
      return createSelector(
        slice,
        // other selectors
        rootState => {
          // console.log('rootState', rootState);
          return null;
        },
        (state, nulla) => {
          // console.log('hasRole role', role);
          // console.log('hasRole state', state);
          // @todo - implement
          return true;
        },
      );
    }),
  }),
  reducers: {
    setUserInfo(state, payload) {
      return {
        ...state,
        user: {
          ...payload,
          settings:
            payload && payload.settings ? JSON.parse(payload.settings) : {},
        },
        isLoggedIn: !!(payload && payload.id),
        initialized: true,
      };
    },
    logout(state, payload) {
      return {
        ...state,
        ...defaultEmptyState,
      };
    },
    setError(state, payload) {
      return {
        ...state,
        ...defaultEmptyState,
        error: payload,
      };
    },
    clearError(state, payload) {
      return {
        ...state,
        error: null,
      };
    },
    setToken(state, payload) {
      localStorage.setItem('notsy_token', payload);
      return {
        ...state,
        token: payload,
      };
    },
    setNextUrl(state, payload) {
      return {
        ...state,
        nextUrl: payload,
      };
    },
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: dispatch => ({
    async login(payload: { username: string; password: string }, rootState) {
      dispatch.AuthModel.setError(null);
      const asyncAction = HttpApi.post('/auth/token/login/', {
        data: {
          username: payload.username,
          password: payload.password,
        },
        ommitToken: true,
      });
      asyncAction
        .then((data: any) => {
          dispatch.AuthModel.setToken(data.authToken);
        })
        .catch(error => {
          dispatch.AuthModel.setError(getErrorFromResponse(error));
        });
      return asyncAction;
    },
    async logout(payload, rootState) {
      const asyncAction = HttpApi.post('/auth/token/logout/');
      asyncAction
        .then(data => {
          localStorage.removeItem('notsy_token');
          dispatch.AuthModel.setUserInfo(null);
        })
        .catch(error => {
          dispatch.AuthModel.setError(error);
        });
      return asyncAction;
    },
    async getUserInfo(token, rootState) {
      const asyncAction = HttpApi.get('/auth/users/me/');
      asyncAction
        .then(data => {
          dispatch.AuthModel.setUserInfo(data);
        })
        .catch(error => {
          dispatch.AuthModel.setUserInfo(null);
          // dispatch.AuthModel.setError({
          //   error: 'Your session has expired. Please login.',
          // });
        });
      return asyncAction;
    },
    async register(payload, rootState) {
      dispatch.AuthModel.setError(null);
      const asyncAction = HttpApi.post('/auth/users/create/', {
        data: {
          email: payload.email,
          password: payload.password,
          username: payload.username,
          invitationToken: payload.invitationToken,
        },
        ommitToken: true,
      });
      asyncAction
        .then(data => {
          Events.emit('AuthModel.register.success');
        })
        .catch(error => {
          if (error.data) {
            dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            dispatch.AuthModel.setError({
              non_field_errors: `${error.status}: ${error.statusText}`,
            });
          } else {
            dispatch.AuthModel.setError({
              non_field_errors: error.toString(),
            });
          }
        });
      return asyncAction;
    },
    async resetPassword(payload, rootState) {
      dispatch.AuthModel.setError(null);
      const asyncAction = HttpApi.post('/auth/users/reset_password/', {
        data: {
          email: payload.email,
        },
        ommitToken: true,
      });
      asyncAction
        .then(data => {
          Events.emit('AuthModel.resetPassword.success');
        })
        .catch(error => {
          if (error.status === 404) {
            dispatch.AuthModel.setError({
              non_field_errors: 'Page not found',
            });
            return;
          }
          if (error.data) {
            dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            dispatch.AuthModel.setError({
              non_field_errors: `${error.status}: ${error.statusText}`,
            });
          } else {
            dispatch.AuthModel.setError({
              non_field_errors: error.toString(),
            });
          }
        });

      return asyncAction;
    },
    async resetPasswordConfirm(payload, rootState) {
      dispatch.AuthModel.setError(null);
      const asyncAction = HttpApi.post('/auth/users/reset_password_confirm/', {
        data: {
          uid: payload.uid,
          token: payload.token,
          new_password: payload.password,
        },
        ommitToken: true,
      });
      asyncAction
        .then(data => {
          Events.emit('AuthModel.resetPasswordConfirm.success');
        })
        .catch(error => {
          if (error.status === 404) {
            dispatch.AuthModel.setError({
              non_field_errors: 'Page not found',
            });
            return;
          }
          if (error.data) {
            dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            dispatch.AuthModel.setError({
              non_field_errors: `${error.status}: ${error.statusText}`,
            });
          } else {
            dispatch.AuthModel.setError({
              non_field_errors: error.toString(),
            });
          }
        });
      return asyncAction;
    },
  }),
};
