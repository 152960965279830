import { styled } from '../../';

const Toolbar = styled('div').attrs({ className: 'Toolbar' })<{
  position?: 'top' | 'bottom';
  align?: 'left' | 'space-between';
  left?: number | string;
  right?: number | string;
}>`
  position: fixed;
  left: ${p => (p.left ? p.left : 0)};
  right: ${p => (p.right ? p.right : 0)};
  bottom: ${p => (p.position === 'bottom' ? 0 : 'auto')};
  top: ${p => (p.position === 'top' ? 0 : 'auto')};
  justify-content: ${p =>
    p.align === 'left' ? 'flex-start' : 'space-between'};
  height: 50px;
  padding: ${p => p.theme.spaceSmallX} ${p => p.theme.spaceSmall};
  border-top: ${p => (p.position === 'top' ? 0 : '1px')} solid
    ${p => p.theme.colorGrey};
  border-bottom: ${p => (p.position === 'top' ? '1px' : 0)} solid
    ${p => p.theme.colorGrey};
  background: ${p =>
    p.theme.toolbarBackgroundColor
      ? p.theme.toolbarBackgroundColor
      : p.theme.colorPrimary};

  display: flex;
  align-items: center;
`;

Toolbar.defaultProps = {
  position: 'bottom',
  align: 'space-between',
};

export { Toolbar };
