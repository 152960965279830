import React, { FunctionComponent } from 'react';
// import plLocale from 'date-fns/locale/pl';
import plLocale from 'date-fns/locale/en';
import { distanceInWordsToNow } from 'date-fns';

import { formattedDate, formattedTime, Tooltip } from '../../';

import styles from './DateTimeAgo.module.scss';

export interface DateTimeAgoProps {
  datetime: string | number;
  withIcons?: boolean;
  withTime?: boolean;
  withTooltip?: boolean;
}

const DateTimeAgoDefaultProps = {
  datetime: +new Date(),
  withIcons: false,
  withTime: true,
  withTooltip: true,
};

const DateTimeAgo: FunctionComponent<DateTimeAgoProps> = ({
  datetime,
  withTooltip,
}) => {
  return (
    <Tooltip text={`${formattedDate(datetime)}  ${formattedTime(datetime)}`}>
      <span className={styles.DateTimeAgo}>
        {distanceInWordsToNow(datetime, {
          locale: plLocale,
          addSuffix: true,
        })}
      </span>
    </Tooltip>
  );
};

DateTimeAgo.defaultProps = DateTimeAgoDefaultProps;

export { DateTimeAgo };
