import marked from 'marked';
import hljs from 'highlight.js';

marked.setOptions({
  breaks: true,
  sanitize: true,
  smartLists: true,
  smartypants: true,
});

const markedRenderer = new marked.Renderer();

markedRenderer.link = (href: string, title: string, text: string) => `
  <a href="${href}" target="_blank" title="${title}">${text}</a>
`;

//  we need to overwrite code renderer because using highlight option does
//  not set properly class for <code> element - it's needed for styling
markedRenderer.code = (code: string, infostring: string) => {
  const infoLang = (infostring || '').match(/\S*/);
  let parsedCode;

  if (infoLang && infoLang[0] && hljs.getLanguage(infoLang[0])) {
    parsedCode = hljs.highlight(infoLang[0], code, true);
  } else {
    parsedCode = hljs.highlightAuto(code);
  }

  const isHighlighted = parsedCode !== null && parsedCode !== code;

  if (!isHighlighted) {
    return `<pre><code class="hljs">${escape(code)}</code></pre>`;
  }

  return `<pre><code class="hljs ${parsedCode ? parsedCode.language : ''}">${
    parsedCode ? parsedCode.value : escape(code)
  }</code></pre>`;
};

export { marked, markedRenderer };
