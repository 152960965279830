const config = {
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  isDevelopment: process.env.NODE_ENV === 'development',
  isProduction: process.env.NODE_ENV === 'production',
  appVersion: `${process.env.REACT_APP_VERSION}`,
  appGitVersion: `${process.env.REACT_APP_GIT_VERSION}`,
};

// // @ts-ignore
// window.config = config;

export default config;
