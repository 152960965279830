import { HttpApi } from 'services';
import { SimpleRestModel } from 'lib/services';

export const NoteModel = SimpleRestModel({
  HttpApi: HttpApi,
  collectionName: 'notes',
  modelName: 'NoteModel',
});

export interface Note {
  body: string;
  createdAt: string;
  group: number;
  id: number;
  isPublic: boolean;
  name: string;
  owner: string;
  publicUrl: string;
  tags: number[];
  updatedAt: string;
}
