import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { store } from 'store';
import { path } from 'rambdax';

/**
 *
 
 We are using render prop instead of component in Route to prevent from
 remounting Component on location change
 - for example when navigating from /somePage/A/beer to /somePage/A/whisky
 we don't want to remount SomePage, only subPages
 
 But... we pass key param to Component to force remounting on location change
 from /somePage/A to /somePage/B
 
 */

export const AuthRoute = ({ component, ...rest }) => {
  const Component = component;
  return (
    <Route
      {...rest}
      render={props => {
        const paramId = path('match.params.id', props);
        const auth = store.getState().AuthModel;
        return auth.isLoggedIn ? (
          <Component {...props} key={paramId} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
