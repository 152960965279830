import { differenceInDays } from 'date-fns';
import { Note } from './NoteModel';

export const isNoteUpdated = (note: Note) => !!note.updatedAt;

export const isNoteUpdatedInLast7Days = (note: Note) =>
  isNoteUpdated(note) && differenceInDays(new Date(), note.updatedAt) < 7;

export const getNoteBodyCharactersCount = (note: Note) =>
  note.body.replace(/[\s\t\n\r]/gi, '').length;

export const getNoteBodyWordsCount = (note: Note) =>
  Array.from(note.body.matchAll(/[\węóąśłżźćńłĘÓĄŚŁŻŹĆŃ]+/g)).length;
