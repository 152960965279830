import { styled } from '../..';

const Spacer = styled.div.attrs({ className: 'Spacer' })<{
  size?: 'default' | 'small' | 'smallX' | 'large';
  horizontal?: boolean;
}>`
  height: ${p =>
    p.horizontal
      ? 0
      : p.size === 'large'
      ? p.theme.spaceLarge
      : p.size === 'small'
      ? p.theme.spaceSmall
      : p.size === 'smallX'
      ? p.theme.spaceSmallX
      : p.theme.space};
  width: ${p =>
    !p.horizontal
      ? 0
      : p.size === 'large'
      ? p.theme.spaceLarge
      : p.size === 'small'
      ? p.theme.spaceSmall
      : p.size === 'smallX'
      ? p.theme.spaceSmallX
      : p.theme.space};
  background: transparent;
  display: ${p => (p.horizontal ? 'inline-block;' : 'block')};
`;

export { Spacer };
