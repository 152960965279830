import { styled } from '../';

export const UiColorGreen = styled.div.attrs({ className: 'UiColorGreen' })`
  color: ${p => p.theme.colorGreen};
`;
export const UiColorRed = styled.div.attrs({ className: 'UiColorRed' })`
  color: #cf1e2f;
`;

export const UiWithMarginSmall = styled.div.attrs({
  className: 'UiWithMarginSmall',
})`
  margin: ${p => p.theme.spaceSmall} 0;
`;

export const UiWhiteSpaceNoWrap = styled.div.attrs({
  className: 'UiWhiteSpaceNoWrap',
})`
  white-space: nowrap;
`;
