import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';

// import * as models from 'models';
import { AuthModel } from 'modules/Auth/authModel';
import { NoteModel } from 'modules/Notes';
import { TagModel } from 'modules/Tags';
import { GroupModel } from 'modules/Groups';
import { UiModel } from 'modules/Ui';
import { OpenedNotesModel } from './modules/OpenedNotes/OpenedNotes';

const persistPlugin = createRematchPersist({
  whitelist: ['UiModel', 'OpenedNotesModel'],
  throttle: 1000,
  version: 1,
});

const loadingPlugin = createLoadingPlugin({});

export interface RootModel {
  AuthModel: typeof AuthModel;
  NoteModel: typeof NoteModel;
  TagModel: typeof TagModel;
  GroupModel: typeof GroupModel;
  UiModel: typeof UiModel;
  OpenedNotesModel: typeof OpenedNotesModel;
}

const models: RootModel = {
  // ...models,
  AuthModel,
  NoteModel,
  TagModel,
  GroupModel,
  UiModel,
  OpenedNotesModel,
};

export const store = init({
  models,
  plugins: [loadingPlugin, selectPlugin(), persistPlugin],
});

export const { dispatch } = store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type StoreRootState = RematchRootState<RootModel>;
