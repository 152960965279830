import { Icon, IconColor, IconFa, IconSize, Spacer, styled } from 'lib/ui';
import React from 'react';
import { IconArrowOpenNote } from './IconArrowOpenNote';
import { store } from 'store';

export interface NotesListItemProps {
  note: any;
  onClick?: Function;
  highlighted?: boolean;
  active?: boolean;
}

const NotesListItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

const NotesListItemLink = styled.a.attrs({ className: '' })<{
  highlighted?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spaceSmallX}
    ${props => props.theme.spaceSmall};
  line-height: 1.4rem;
  background: ${props =>
    props.highlighted ? props.theme.colorDirtyWhite : 'none'};

  .new-tab-icon-link {
    opacity: 0;
  }

  :hover {
    background: ${props => props.theme.backgroundHover};

    .new-tab-icon-link {
      opacity: 100;
    }
  }
`;

const NotesListItemName = styled('span')<{ active: boolean }>`
  font-weight: ${p =>
    p.active ? p.theme.fontWeightBold : p.theme.fontFamilyDefault};
  flex-grow: 1;
`;

const NotesListItem = ({
  note,
  onClick,
  active,
  highlighted,
}: NotesListItemProps) => {
  return (
    <NotesListItemStyled
      onClick={event => {
        event.preventDefault();
        if (event.metaKey) {
          store.dispatch.OpenedNotesModel.openNote(note);
        }
        if (onClick) {
          onClick(note);
        }
      }}
    >
      <NotesListItemLink highlighted={highlighted}>
        {active && (
          <>
            <Icon name="angle-right" color={IconColor.Grey} />
            <Spacer size="smallX" horizontal />
          </>
        )}
        <NotesListItemName active={!!active}>{note.name}</NotesListItemName>

        <span
          className={'new-tab-icon-link ml-3 text-gray-500 cursor-pointer'}
          onClick={event => {
            store.dispatch.OpenedNotesModel.openNote(note);
          }}
        >
          <IconArrowOpenNote></IconArrowOpenNote>
        </span>
      </NotesListItemLink>
    </NotesListItemStyled>
  );
};

NotesListItem.defaultProps = {
  onClick: () => {},
};

export { NotesListItem };
