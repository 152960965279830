import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { path } from 'rambdax';
import { Formik } from 'formik';
import { useUnmount } from 'react-use';

import { Group } from 'lib/ui';
import { ButtonSubmit, FormError, FormField } from 'lib/forms';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.login,
  }),
  ({ AuthModel: { login, getUserInfo, clearError } }) => ({
    login,
    getUserInfo,
    clearError,
  }),
);

const LoginPage = enhance(props => {
  useEffect(() => {
    if (props.auth.token) {
      props.getUserInfo();
    }
  }, [props.auth.token, props.getUserInfo]);

  useUnmount(props.clearError);

  const { loading } = props;

  const isLoggedIn = path('auth.isLoggedIn', props);
  if (isLoggedIn) {
    return <Redirect to={props.auth.nextUrl ? props.auth.nextUrl : '/'} />;
  }

  return (
    <AuthBasePage title="Login">
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validate={values => {
          const errors: any = {};
          if (!values.username) {
            errors.username = 'Enter your username';
          }
          if (!values.password) {
            errors.password = 'Enter password';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          props
            .login({
              username: values.username,
              password: values.password,
            })
            .then(data => {
              bag.setSubmitting(false);
            });
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}

            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField
                    name="username"
                    placeholder=""
                    label="Username"
                    onEnter={formikProps.handleSubmit}
                    {...formikProps}
                  />
                </Group>
                <Group>
                  <FormField
                    name="password"
                    placeholder=""
                    label="Password"
                    type="password"
                    onEnter={formikProps.handleSubmit}
                    {...formikProps}
                  />
                </Group>
              </Group>

              <div className="mt-6">
                <ButtonSubmit
                  disabled={
                    formikProps.isSubmitting || !formikProps.isValid || loading
                  }
                  loading={loading || formikProps.isSubmitting}
                  label="Login"
                  onClick={formikProps.handleSubmit}
                  iconName="sign-in"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>

      <div className="mt-4">
        <p className="text-center">
          <Link to="/reset-password">Forgot your password?</Link>
        </p>
      </div>
    </AuthBasePage>
  );
});

export { LoginPage };
