import classNames from 'classnames';
import React, { FunctionComponent } from "react";

import { Icon, IconSize } from '../../';

import styles from './EmptyState.module.scss';

export interface EmptyStateProps {
  message?: string;
  icon?: string;
  withIcon?: boolean;
  simple?: boolean;
  inline?: boolean;
  children?: any;
}

const EmptyStateDefaultProps = {
  message: 'Brak danych',
  icon: 'empty-set',
  withIcon: true,
  simple: false,
  inline: false,
  children: null,
};

const EmptyStateIcon = props =>
  props.withIcon && <Icon name={props.icon} size={IconSize.LargeXX} />;

const EmptyState:FunctionComponent<EmptyStateProps> = (props: EmptyStateProps) => {
  console.log('EmptyState props', props);
  return (
    <div
      className={classNames(styles.EmptyState, {
        [styles.EmptyStateSimple]: props.simple,
        [styles.EmptyStateInline]: props.inline,
      })}
    >
      <div className={styles.Icon}>
        <EmptyStateIcon {...props} />
      </div>
      <div className={styles.Message}>{props.message}</div>

      {props.children && (
        <div className={styles.Children}>{props.children}</div>
      )}
    </div>
  );
};

EmptyState.defaultProps = EmptyStateDefaultProps;

export { EmptyState };
