import axios, { AxiosRequestConfig } from 'axios';
import { omit } from 'rambdax';

export interface httpClientOptions extends AxiosRequestConfig {
  localStorageKeyWithToken?: string;
  onUnauthorized?: Function;
  debug?: boolean;
}
export interface httpClientRequestOptions extends AxiosRequestConfig {
  ommitToken?: boolean;
  debug?: boolean;
}

export const httpClient = (options: httpClientOptions) => {
  // console.log(
  //   '%c httpClient ',
  //   'background:yellow; color: black; padding:2px 20px;',
  //   options,
  // );
  const applyAuthorizationHeader = headers => {
    const token = localStorage.getItem(
      options.localStorageKeyWithToken || 'token',
    );
    if (!token) {
      return headers;
    }
    return {
      ...headers,
      Authorization: `Token ${token}`,
    };
  };

  const buildHeaders = (headers, ommitToken) => {
    const requestHeaders = applyAuthorizationHeader({
      'X-Requested-With': 'XMLHttpRequest',
      ...headers,
    });
    if (ommitToken) {
      delete requestHeaders.Authorization;
    }
    return requestHeaders;
  };

  const logResponse = response => {
    if (!options.debug && !response.config.debug) {
      return;
    }

    // console.log(
    //   `%c ::: axios.interceptors.RESPONSE
    //   ${response.status} -> ${response.config.url}
    //   `,
    //   'color: green; padding:2px 1px;',
    //   response.data,
    // );
  };

  const logRequest = request => {
    if (!options.debug && !request.debug) {
      return;
    }

    // console.log(
    //   `%c ::: axios.interceptors.REQUEST
    //   ${request.baseUrl}${request.url}
    //   `,
    //   'color: green; padding:2px 1px;',
    //   request,
    // );
  };

  const logError = response => {
    if (!options.debug && !response.config.debug) {
      return;
    }

    // console.log(
    //   `%c ::: axios.interceptors.response.error
    //   ${response.status} -> ${response.config.url}
    //   `,
    //   'color: red; padding:2px 1px;',
    //   response.data,
    // );
  };

  //  create axios instance
  const axiosConfig = omit(
    ['localStorageKeyWithToken', 'onUnauthorized', 'debug'],
    options,
  );
  const instance = axios.create({
    validateStatus: status => {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
    ...axiosConfig,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    config => {
      logRequest(config);
      return config;
    },
    error => {
      // console.log('interceptors.request.error', error);
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    response => {
      logResponse(response);

      if (response.status === 401 && options.onUnauthorized) {
        options.onUnauthorized(response);
      }

      if (response.status >= 300) {
        return Promise.reject(response);
      }

      return response.data;
    },
    error => {
      logError(error);
      // console.log('interceptors.response.error', error);
      // Do something with response error
      return Promise.reject(error);
    },
  );

  return {
    request(requestOptions: httpClientRequestOptions) {
      const { ommitToken, debug, ...axiosRequestOptions } = requestOptions;

      return instance.request({
        ...axiosRequestOptions,
        // url: buildUrl(requestOptions),
        headers: buildHeaders(requestOptions.headers, ommitToken),
        params: {
          ...requestOptions.params,
        },
        data: {
          ...requestOptions.data,
        },
      });
    },
    get(url: string, requestOptions?: httpClientRequestOptions) {
      return this.request({
        method: 'get',
        url,
        ...requestOptions,
      });
    },
    post(url: string, requestOptions?: httpClientRequestOptions) {
      return this.request({
        method: 'post',
        url,
        ...requestOptions,
      });
    },
    put(url: string, requestOptions?: httpClientRequestOptions) {
      return this.request({
        method: 'put',
        url,
        ...requestOptions,
      });
    },
    patch(url: string, requestOptions?: httpClientRequestOptions) {
      return this.request({
        method: 'patch',
        url,
        ...requestOptions,
      });
    },
    delete(url: string, requestOptions?: httpClientRequestOptions) {
      return this.request({
        method: 'delete',
        url,
        ...requestOptions,
      });
    },
  };
};
