import { message } from 'antd';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useLifecycles, useToggle } from 'react-use';
import { Formik } from 'formik';

import { Group } from 'lib/ui';
import { FormError, FormField, ButtonSubmit } from 'lib/forms';
import { Events } from 'services';
import { store } from 'store';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.resetPassword,
  }),
  state => ({ AuthModel: { resetPasswordConfirm, clearError } }) => ({
    resetPasswordConfirm,
    clearError,
  }),
);

const ResetPasswordConfirmPage = enhance(props => {
  const [success, toggleSuccess] = useToggle(false);
  const redirectToLogin = useRef<boolean>();

  useLifecycles(
    () => {
      Events.on('AuthModel.resetPassword.success', () => {
        redirectToLogin.current = true;
      });
    },
    () => {
      Events.off('AuthModel.resetPassword.success', true);
      props.clearError();
    },
  );

  if (redirectToLogin.current) {
    // @todo - display some message
    return <Redirect to={'/login'} />;
  }

  const {
    loading,
    match: { params },
  } = props;

  console.log(
    '%c ResetPasswordConfirmPage ',
    'background:yellow; color: black; padding:2px 20px;',
    params,
  );

  return (
    <AuthBasePage title="Password reset">
      <Formik
        initialValues={{
          password: '',
        }}
        validate={values => {
          const errors: any = {};
          if (!values.password) {
            errors.password = 'Enter your new password';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          store.dispatch.AuthModel.resetPasswordConfirm({
            uid: params.uid,
            token: params.token,
            password: values.password,
          })
            .then(() => {
              toggleSuccess();
            })
            .finally(() => bag.setSubmitting(false));
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}

            {success && (
              <div className="text-green-700">
                Password has been changed. <br />
                Go to <Link to="/login">login page</Link> and login to your
                account
              </div>
            )}

            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField
                    name="password"
                    placeholder=""
                    label="New password"
                    type="password"
                    {...formikProps}
                  />
                </Group>
              </Group>

              <div className="mt-6">
                <ButtonSubmit
                  disabled={
                    formikProps.isSubmitting || !formikProps.isValid || loading
                  }
                  loading={loading || formikProps.isSubmitting}
                  label="Set new password"
                  onClick={formikProps.handleSubmit}
                  iconName="key"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>

      <div className="mt-4">
        <p className="text-center">
          <Link to="/login">Back to login page</Link>
        </p>
      </div>
    </AuthBasePage>
  );
});

export { ResetPasswordConfirmPage };
