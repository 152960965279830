import React from 'react';

export const getMonthLabel = (count: number) => {
  const number = count % 10;
  if (number === 1) return 'miesiąc';
  if (number >= 2 && count <= 4) return 'miesiące';
  return 'miesięcy';
};
export const getMinuteLabel = (count: number) => {
  const number = count % 10;
  if (number === 1) return 'minutę';
  if (number >= 2 && count <= 4) return 'minuty';
  return 'minut';
};
export const getDayLabel = count => {
  const number = count % 10;
  if (number === 1) return 'dzień';
  return 'dni';
};

export interface WeekDayMeta {
  namePl: string;
  nameEn: string;
  shortnamePl: string;
  shortnameEn: string;
}

export const weekdays: WeekDayMeta[] = [
  {
    namePl: 'Poniedziałek',
    nameEn: 'Monday',
    shortnamePl: 'Pn',
    shortnameEn: 'Mon',
  },
  {
    namePl: 'Wtorek',
    nameEn: 'Tuesday',
    shortnamePl: 'Wt',
    shortnameEn: 'Tue',
  },
  {
    namePl: 'Środa',
    nameEn: 'Wednesday',
    shortnamePl: 'Śr',
    shortnameEn: 'Wed',
  },
  {
    namePl: 'Czwartek',
    nameEn: 'Thursday',
    shortnamePl: 'Cz',
    shortnameEn: 'Thu',
  },
  {
    namePl: 'Piątek',
    nameEn: 'Friday',
    shortnamePl: 'Pt',
    shortnameEn: 'Fri',
  },
  {
    namePl: 'Sobota',
    nameEn: 'Saturday',
    shortnamePl: 'So',
    shortnameEn: 'Sat',
  },
  {
    namePl: 'Niedziela',
    nameEn: 'Sunday',
    shortnamePl: 'Nd',
    shortnameEn: 'Sun',
  },
];

//   for antd
export const TimePickerDefaults = {
  TimeFormat: 'HH:mm',
  MinuteStep: 15,
  StartTime: '12:00',
  EndTime: '21:00',
};

export const DateTimePickerDefaults = {
  DateTimeFormat: '"MM/DD HH:mm"',
};

export const TimePickerDefaultsProps = {
  placeholder: '',
  minuteStep: TimePickerDefaults.MinuteStep,
  format: TimePickerDefaults.TimeFormat,
  clearIcon: null,
  allowClear: false,
  style: {
    width: '70px',
  },
  suffixIcon: <></>,
};
