import React, { FunctionComponent, ReactNode } from 'react';

import { Icon, IconFa, styled } from '../..';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.space};
`;

export interface LoadingProps {
  children?: ReactNode;
  text?: string;
}

const LoadingDefaultProps = {
  text: 'Trwa ładowanie',
};

const Loading: FunctionComponent<LoadingProps> = (props: LoadingProps) => {
  return (
    <LoadingContainer>
      <Icon name="spinner" fa={IconFa.Fal} spin />
      <span className={'ml-2'}>{props.text}</span>
    </LoadingContainer>
  );
};

Loading.defaultProps = LoadingDefaultProps;

export { Loading };
