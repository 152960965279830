import React, { FunctionComponent, ReactNode, useRef } from 'react';

import { styled, Button } from 'lib/ui';

export interface ButtonUploadProps {
  children?: ReactNode;
  onChange: (file: File | null) => void;
  label?: string;
  iconName?: string;
}

const ButtonUploadDefaultProps = {
  label: 'Dodaj plik',
  iconName: 'upload',
};

const ButtonUploadContainer = styled.div``;
const InputStyled = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
`;
const ButtonStyled = styled(Button)`
  position: relative;
`;

export const ButtonUpload: FunctionComponent<ButtonUploadProps> = props => {
  const inputElement = useRef<HTMLInputElement | null>(null);

  return (
    <ButtonUploadContainer>
      <ButtonStyled
        label={props.label}
        iconName={props.iconName}
        onClick={() => {
          if (inputElement.current) {
            const clickEvent = new MouseEvent('click');
            inputElement.current.dispatchEvent(clickEvent);
          }
        }}
      >
        <InputStyled
          ref={inputElement}
          type="file"
          onChange={e => {
            const files = e.target.files;
            if (files && files.length) {
              props.onChange(files[0]);
            } else {
              props.onChange(null);
            }
          }}
        />
      </ButtonStyled>
    </ButtonUploadContainer>
  );
};

ButtonUpload.defaultProps = ButtonUploadDefaultProps;
