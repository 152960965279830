import React from 'react';
import classnames from 'classnames';

import styles from './Divider.module.scss';

export enum DividerType {
  Horizontal = 'horizontal', // default
  Vertical = 'vertical',
}

export enum DividerTextOrientation {
  Left = 'left',
  Center = 'center', //  default
  Right = 'right',
}

export interface DividerProps {
  type?: DividerType;
  text?: string;
  textOrientation?: DividerTextOrientation;
  textStrong?: boolean;
  hidden?: boolean;
  noMargin?: boolean;
  color?: string;
}

const Divider = (props: DividerProps) => {
  if (props.text) {
    return (
      <div
        className={classnames(styles.DividerWithText, {
          [styles.textLeft]:
            props.textOrientation === DividerTextOrientation.Left,
          [styles.textRight]:
            props.textOrientation === DividerTextOrientation.Right,
        })}
        style={{
          backgroundColor: props.color ? props.color : '',
        }}
      >
        <div className={styles.line} />
        <div
          className={classnames(styles.text, {
            [styles.textStrong]: props.textStrong,
          })}
        >
          {props.text}
        </div>
        <div className={styles.line} />
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.Divider, {
        [styles.vertical]: props.type === DividerType.Vertical,
        [styles.hidden]: props.hidden,
        [styles.noMargin]: props.noMargin,
      })}
      style={{
        backgroundColor: props.color ? props.color : '',
      }}
    />
  );
};

export { Divider };
