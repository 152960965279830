import { TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import { path } from 'rambdax';
import React, { FunctionComponent, ReactNode } from 'react';
import { FormikProps, FormikValues } from 'formik';

import { TimePickerDefaults, TimePickerDefaultsProps } from 'lib/ui/utils';
import { Group, styled } from 'lib/ui';
import { FormFieldLabel, FormFieldContainer, FieldError } from 'lib/forms';

export interface TimeWeekPickerProps extends FormikProps<FormikValues> {
  children?: ReactNode;
  name: string;
  label?: string;
}

const TimeWeekPickerContainer = styled.div`
  width: 90px;
`;

export const defaultStartTime = moment('12:00', TimePickerDefaults.TimeFormat);
export const defaultEndTime = moment('21:00', TimePickerDefaults.TimeFormat);

const TimeWeekPicker: FunctionComponent<TimeWeekPickerProps> = props => {
  const value = props.values[props.name];

  const setTime = (dayIndex, timeIndex, time) => {
    const dayValue = [...value[dayIndex]];
    dayValue[timeIndex] = time;
    props.setFieldValue(props.name, {
      ...value,
      [dayIndex]: dayValue,
    });
    props.setFieldTouched(props.name, true);
  };
  const isTouched = path(props.name, props.touched);
  const error = isTouched && path(props.name, props.errors);

  return (
    <FormFieldContainer>
      {props.label && <FormFieldLabel>{props.label}</FormFieldLabel>}
      <Group>
        {[
          { dayId: 'monday', dayName: 'Poniedziałek' },
          { dayId: 'tuesday', dayName: 'Wtorek' },
          { dayId: 'wednesday', dayName: 'Środa' },
          { dayId: 'thursday', dayName: 'Czwartek' },
          { dayId: 'friday', dayName: 'Piątek' },
          { dayId: 'saturday', dayName: 'Sobota' },
          { dayId: 'sunday', dayName: 'Niedziela' },
        ].map(dayData => (
          <TimeWeekPickerContainer key={dayData.dayId}>
            <Group vertical>
              <FormFieldLabel>{dayData.dayName}</FormFieldLabel>
              <TimePicker
                {...TimePickerDefaultsProps}
                value={value[dayData.dayId][0]}
                onChange={time => {
                  setTime(dayData.dayId, 0, time);
                }}
              />
              <TimePicker
                {...TimePickerDefaultsProps}
                value={value[dayData.dayId][1]}
                onChange={time => {
                  setTime(dayData.dayId, 1, time);
                }}
              />
            </Group>
          </TimeWeekPickerContainer>
        ))}
      </Group>
      {error && <FieldError error={error} />}
    </FormFieldContainer>
  );
};

export { TimeWeekPicker };
