import { httpClient } from 'lib/services';

import config from 'config';

export const HttpApi = httpClient({
  baseURL: config.apiUrl,
  localStorageKeyWithToken: 'notsy_token',
  debug: true,
  onUnauthorized: res => {
    console.log(
      '%c onUnauthorized => res:  ',
      'background:#ddd; color: black; padding:2px 20px;',
      res,
    );
  },
});
