import { message } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import { Group } from 'lib/ui';
import { ButtonSubmit, FormField, FormError } from 'lib/forms';
import { useToggle } from 'react-use';
import { Events } from 'services';
import { store } from 'store';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.resetPassword,
  }),
  state => ({ AuthModel: { resetPassword, clearError } }) => ({
    resetPassword,
    clearError,
  }),
);

const ResetPasswordPage = enhance(props => {
  const [success, toggleSuccess] = useToggle(false);

  useEffect(() => {
    Events.on('AuthModel.resetPassword.success', () => {
      // @todo - display message
    });

    return () => {
      Events.off('AuthModel.resetPassword.success', true);
      props.clearError();
    };
  }, []);

  const { loading } = props;

  return (
    <AuthBasePage title="Password reset">
      <Formik
        initialValues={{
          email: '',
        }}
        validate={values => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Enter your e-mail';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          store.dispatch.AuthModel.resetPassword(values)
            .then(() => {
              toggleSuccess();
            })
            .finally(() => bag.setSubmitting(false));
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}
            {success && (
              <div className="text-green-700">
                Message with reset link has been sent
              </div>
            )}

            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField
                    name="email"
                    placeholder=""
                    label="E-mail address"
                    {...formikProps}
                  />
                </Group>
              </Group>

              <div className="mt-6">
                <ButtonSubmit
                  disabled={
                    formikProps.isSubmitting || !formikProps.isValid || loading
                  }
                  loading={loading || formikProps.isSubmitting}
                  label="Reset password"
                  onClick={formikProps.handleSubmit}
                  iconName="key"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>

      <div className="mt-4">
        <p className="text-center">
          <Link to="/login">Back to login page</Link>
        </p>
      </div>
    </AuthBasePage>
  );
});

export { ResetPasswordPage };
