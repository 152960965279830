import React, { useEffect, useState } from 'react';
import { DateTimeAgo, Icon, IconFa, IconSize, Tooltip } from 'lib/ui';
import { Note } from 'modules/Notes';
import {
  getNoteBodyCharactersCount,
  getNoteBodyWordsCount,
} from 'modules/Notes/NoteModel.utils';
import { formatNumber } from 'utils/formatNumber';

export interface NoteStatusbarProps {
  note: Note;
}

export const NoteStatusbar: React.FC<NoteStatusbarProps> = props => {
  const [stats, setStats] = useState<{
    characters: number;
    words: number;
  } | null>(null);

  useEffect(() => {
    setStats({
      characters: getNoteBodyCharactersCount(props.note),
      words: getNoteBodyWordsCount(props.note),
    });
  }, [props.note]);

  return (
    <div className="shadow-inner p-2 border-gray-300 border-t bg-gray-100">
      <div className="flex items-center flex-wrap justify-between text-xs leading-5 text-gray-500">
        {stats && (
          <>
            <div className="flex items-center space-x-2">
              <Tooltip text="All characters count">
                <div className="flex items-center space-x-1">
                  <span className="text-gray-600 text-base font-medium">A</span>
                  <span>{formatNumber(props.note.body.length)}</span>
                </div>
              </Tooltip>
              <Tooltip text="Characters count (without white spaces)">
                <div className="flex items-center space-x-1">
                  <span className="text-gray-600 text-base font-medium">C</span>
                  <span>{formatNumber(stats.characters)}</span>
                </div>
              </Tooltip>
              <Tooltip text="Words count">
                <div className="flex items-center space-x-1">
                  <span className="text-gray-600 text-base font-medium">W</span>
                  <span>{formatNumber(stats.words)}</span>
                </div>
              </Tooltip>
            </div>
          </>
        )}
        <div className="flex items-center space-x-2">
          <Tooltip text="Created date">
            <Icon name="file-plus" size={IconSize.Small} fa={IconFa.Fas} />
          </Tooltip>
          <DateTimeAgo datetime={props.note.createdAt} />
          {props.note.updatedAt && (
            <>
              <Tooltip text="Last update">
                <Icon name="pencil" size={IconSize.Small} fa={IconFa.Fad} />
              </Tooltip>
              <DateTimeAgo datetime={props.note.updatedAt} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
