import { Menu, message } from 'antd';
import { PageMeta } from 'components';
import { Badge, BadgeColor, BadgeSize } from 'components/Badge';
import config from 'config';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  EditableText,
  Group,
  Icon,
  IconFa,
  IconSize,
  SimplePickerDialog,
  Spacer,
  styled,
} from 'lib/ui';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';

const enhance = connect(
  ({ NoteModel, TagModel, GroupModel, loading, UiModel }) => ({
    NoteModelStore: NoteModel,
    loading,
    TagModelStore: TagModel,
    GroupModelStore: GroupModel,
    UiModelStore: UiModel,
  }),
  ({ NoteModel, TagModel, GroupModel, UiModel }) => ({
    NoteModel,
    TagModel,
    GroupModel,
    UiModel,
  }),
);

export interface NotePageMetaProps {
  note: any;
  onEdit: () => void;
  onShare: () => void;
  onShareDisable: () => void;
  onDelete: () => void;
  isEditMode: boolean;
}

const NotePageMetaDefaultProps = {};

const CollapseButtonContainer = styled.div`
  position: absolute;
  bottom: ${p => p.theme.space};
  right: ${p => p.theme.spaceSmall};
`;

const NotePageMeta: FunctionComponent<NotePageMetaProps> = enhance(props => {
  const [tagPickerVisible, toggleTagPickerlVisibility] = useToggle(false);
  const [categoryPickerVisible, toggleCategoryPickerlVisibility] = useToggle(
    false,
  );

  // TODO - fix typings
  const { note } = props;
  if (!note) {
    return null;
  }

  const noteGroup = note.group && props.GroupModelStore.dataById[note.group];

  return (
    <PageMeta>
      <CollapseButtonContainer>
        <Button
          variant={ButtonVariant.Plain}
          size={ButtonSize.Small}
          iconName={
            props.UiModelStore.notePageMetaCollapsed
              ? 'chevron-down'
              : 'chevron-up'
          }
          onClick={() => {
            props.UiModel.toggleNotePageMeta();
          }}
        />
      </CollapseButtonContainer>

      <Group vertical>
        {props.UiModelStore.notePageMetaCollapsed ? (
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {note.name}
          </h3>
        ) : (
          <Group>
            <EditableText
              text={note.name}
              onUpdate={(value, closeEditing, setLoading) => {
                props.NoteModel.updateItem({
                  id: note.id,
                  data: {
                    name: value,
                  },
                })
                  .then(() => {
                    message.success(`${note.name} updated.`);
                  })
                  .catch(() => {
                    message.error(`Error`);
                  })
                  .finally(() => {
                    closeEditing();
                  });
              }}
            />
            <Button
              variant={ButtonVariant.Plain}
              size={ButtonSize.Small}
              iconName="ellipsis-v-alt"
              dropdown={
                <Menu>
                  {!note.isPublic && (
                    <Menu.Item
                      key="0"
                      onClick={props.onShare}
                      disabled={props.isEditMode}
                    >
                      <Icon name={'share'} size={IconSize.Small} />
                      <Spacer size="small" horizontal />
                      Share
                    </Menu.Item>
                  )}
                  {note.isPublic && (
                    <Menu.Item
                      key="0"
                      onClick={props.onShareDisable}
                      disabled={props.isEditMode}
                    >
                      <Icon name={'share'} size={IconSize.Small} />
                      <Spacer size="small" horizontal />
                      Unshare
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="0"
                    onClick={props.onEdit}
                    disabled={props.isEditMode}
                  >
                    <Icon name={'edit'} size={IconSize.Small} />
                    <Spacer size="small" horizontal />
                    Edit
                  </Menu.Item>
                  <Menu.Item key="1" onClick={props.onDelete}>
                    <Icon name={'trash'} size={IconSize.Small} />
                    <Spacer size="small" horizontal />
                    Delete
                  </Menu.Item>
                </Menu>
              }
            />
          </Group>
        )}

        {!props.UiModelStore.notePageMetaCollapsed && (
          <>
            <div className="mt-2 flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <Button
                  iconName="tags"
                  label="Tags"
                  iconFa={IconFa.Fad}
                  size={ButtonSize.Small}
                  onClick={() => toggleTagPickerlVisibility(true)}
                />

                {note.tags &&
                  note.tags.map(tagId => {
                    const tag = props.TagModelStore.dataById[tagId];
                    if (tag) {
                      return (
                        <Badge
                          key={tagId}
                          rounded
                          color={BadgeColor.blue}
                          size={BadgeSize.large}
                        >
                          {tag.name}
                        </Badge>
                      );
                    }
                    return null;
                  })}
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  iconName="layer-group"
                  label="Group"
                  iconFa={IconFa.Fad}
                  size={ButtonSize.Small}
                  onClick={() => {
                    toggleCategoryPickerlVisibility(true);
                  }}
                />
                {noteGroup && (
                  <Badge
                    key={noteGroup.id}
                    rounded
                    color={BadgeColor.default}
                    size={BadgeSize.large}
                  >
                    {noteGroup.name}
                  </Badge>
                )}
              </div>

              {note.isPublic && (
                <div>
                  <a
                    href={`${config.apiUrl}/notes-shared/${note.publicUrl}`}
                    target="_blank"
                    className="flex items-center space-x-2 text-sm ml-2"
                  >
                    <Icon
                      name={'external-link'}
                      fa={IconFa.Fad}
                      size={IconSize.Small}
                    />
                    <span>public url</span>
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </Group>

      {tagPickerVisible && (
        <SimplePickerDialog
          title="Tags picker"
          items={props.TagModelStore.data}
          selectedIds={note.tags}
          multiple
          onSave={(itemIds, items) => {
            props.NoteModel.updateItem({
              id: note.id,
              data: {
                tags: itemIds,
              },
            })
              .then(() => {
                message.success(`${note.name} updated.`);
              })
              .catch(() => {
                message.error(`Error`);
              })
              .finally(() => {
                toggleTagPickerlVisibility(false);
              });
          }}
          onCancel={() => {
            toggleTagPickerlVisibility(false);
          }}
        />
      )}

      {categoryPickerVisible && (
        <SimplePickerDialog
          title="Group picker"
          items={props.GroupModelStore.data}
          selectedIds={[note.group]}
          multiple={false}
          onSave={(itemIds, items) => {
            props.NoteModel.updateItem({
              id: note.id,
              data: {
                group: itemIds,
              },
            })
              .then(() => {
                message.success(`${note.name} updated.`);
              })
              .catch(() => {
                message.error(`Error`);
              })
              .finally(() => {
                toggleCategoryPickerlVisibility(false);
              });
          }}
          onCancel={() => {
            toggleCategoryPickerlVisibility(false);
          }}
        />
      )}
    </PageMeta>
  );
});

NotePageMeta.defaultProps = NotePageMetaDefaultProps;

export { NotePageMeta };
