import { Input } from 'lib/forms';
import { Button, ButtonSize, ButtonVariant, Spacer, styled } from 'lib/ui';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';

export interface EditableTextProps {
  text: string;
  onUpdate?: (
    value: string,
    closeEditing: () => void,
    setLoading: (state: boolean) => void,
  ) => void;
}

const EditableTextDefaultProps = {};

const EditableTextContainer = styled.div.attrs({
  className: 'EditableTextContainer',
})`
  display: flex;
  align-items: center;
  width: 100%;
`;

const EditableInput = styled(Input).attrs({
  className: 'EditableTextContainer',
})`
  flex-grow: 1;
  width: auto;
`;

export const EditableText: FunctionComponent<EditableTextProps> = props => {
  const [editing, setEditing] = useToggle(false);
  const [loading, setLoading] = useToggle(false);
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(props.text);
  }, [props.text]);

  useEffect(() => {
    if (editing && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [editing]);

  const onUpdate = () => {
    if (props.onUpdate) {
      setLoading(true);
      props.onUpdate(
        value,
        () => {
          setLoading(false);
          setEditing(false);
        },
        setLoading,
      );
    }
  };

  return (
    <EditableTextContainer>
      {editing && (
        <>
          <EditableInput
            value={value}
            ref={inputRef}
            onChange={ev => setValue(ev.currentTarget.value)}
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                ev.stopPropagation();
                onUpdate();
              }
              if (ev.key === 'Escape') {
                ev.preventDefault();
                ev.stopPropagation();
                setEditing(false);
              }
            }}
          />
          <Spacer horizontal size="small" />
          <Button
            iconName="save"
            label="Save"
            variant={ButtonVariant.Primary}
            loading={loading}
            onClick={onUpdate}
          />
          <Spacer horizontal size="small" />
          <Button
            label="Cancel"
            variant={ButtonVariant.Plain}
            disabled={loading}
            onClick={() => {
              setEditing(false);
            }}
          />
        </>
      )}
      {!editing && (
        <div className="flex items-center space-x-2">
          <h3 className="m-0 text-lg leading-6 font-medium text-gray-900">
            {props.text}
          </h3>
          <Button
            iconName="edit"
            size={ButtonSize.Small}
            variant={ButtonVariant.Plain}
            onClick={() => {
              setEditing(true);
            }}
          />
        </div>
      )}
    </EditableTextContainer>
  );
};

EditableText.defaultProps = EditableTextDefaultProps;
