import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { defaultTheme, ThemeProvider } from 'lib/ui';
import { SentryErrorBoundary } from 'lib/utils';

import * as Sentry from '@sentry/browser';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { theme } from './theme';
import config from './config';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    maxBreadcrumbs: 10,
    debug: !config.isProduction,
    environment: config.isProduction ? 'production' : 'dev',
    enabled: process.env.NODE_ENV === 'production',
  });
}
// should have been called before using it here
// ideally before even rendering your react app

ReactDOM.render(
  <SentryErrorBoundary
    enabled={!!sentryDsn && process.env.NODE_ENV === 'production'}
  >
    <Provider store={store}>
      <ThemeProvider
        theme={{
          ...defaultTheme,
          ...theme,
        }}
      >
        <App />
      </ThemeProvider>
    </Provider>
  </SentryErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
