import { styled } from '../../ui';

export const SearchInputClearButtonContainer = styled.div.attrs({
  className: 'SearchInputClearButtonContainer',
})`
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  padding: ${p => p.theme.spaceSmallX} ${p => p.theme.spaceSmall};
  cursor: pointer;
  border-radius: ${p => p.theme.borderRadius};
  opacity: 0.7;
  transition: opacity 0.25s ease-in-out;

  :hover {
    opacity: 1;
  }
`;
