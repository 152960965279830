import { Events } from '..';
import { getErrorFromResponse } from './getErrorFromResponse';

export interface SimpleRestModelOptions {
  HttpApi: any; // http object created from lib/services/http.ts
  collectionName: string;
  modelName?: string;
  apiPath?: string;
  getApiPath?: (payload: any, action: string) => string;
}

interface Payload {
  data?: any;
  params?: object;
  id?: string | number;
  action?: string;
}

export const SimpleRestModel = ({
  HttpApi,
  collectionName,
  modelName,
  apiPath,
  getApiPath,
}: SimpleRestModelOptions) => {
  const collectionStoreName = modelName || collectionName;
  const baseApiPath = `/${apiPath || collectionName}/`;

  const _getApiPath = (payload, action: string) => {
    if (getApiPath) {
      return getApiPath(payload, action);
    }

    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return baseApiPath;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `${baseApiPath}${payload.id}/`;
      case 'customCollectionAction':
        return `${baseApiPath}${payload.action}/`;
      case 'customItemAction':
        return `${baseApiPath}${payload.id}/${payload.action}/`;
    }
  };

  const initialState = {
    dataById: {},
    data: [],
    dataIds: [],
    errors: null,
    loading: {},
  };

  return {
    name: collectionStoreName,
    state: initialState,
    reducers: {
      'AuthModel/logout': (state, payload) => {
        return initialState;
      },
      setData(state, payload: any[]) {
        const dataById = payload.reduce((acc, item) => {
          acc[item.id] = {
            ...state.dataById[item.id],
            ...item,
          };
          return acc;
        }, {});
        return {
          dataById,
          dataIds: payload.map(item => item.id),
          data: payload,
        };
      },
      setItem(state, data: { itemIdFromPayload: any; item: any }) {
        return {
          ...state,
          dataById: {
            ...state.dataById,
            [data.itemIdFromPayload]: data.item,
          },
          data: state.data.map(stateItem => {
            if (stateItem.id === data.itemIdFromPayload) {
              return data.item;
            }
            return stateItem;
          }),
        };
      },
      addItem(state, item) {
        return {
          dataById: {
            ...state.dataById,
            [item.id]: item,
          },
          dataIds: [...state.dataIds, item.id],
          data: [...state.data, item],
        };
      },
      removeItem(state, item) {
        const dataById = { ...state.dataById };
        delete dataById[item.id];
        return {
          dataById,
          dataIds: state.dataIds.filter(itemId => itemId !== item.id),
          data: state.data.filter(stateItem => stateItem.id !== item.id),
        };
      },
      reset(state) {
        return {
          dataById: {},
          data: [],
          dataIds: [],
        };
      },
      setError(state, errors) {
        return {
          ...state,
          errors,
        };
      },
      clearError(state, error) {
        return {
          ...state,
          errors: null,
        };
      },
    },
    effects: dispatch => ({
      loadCollection(payload: Payload = {}) {
        // dispatch[collectionStoreName].setLoading('loadingCollection', true);
        const asyncAction = HttpApi.get(
          _getApiPath(payload, 'loadCollection'),
          payload,
        );
        // console.log('asyncAction', asyncAction);
        asyncAction
          .then(data => {
            // console.warn('then, data', data);
            dispatch[collectionStoreName].setData(data);
            Events.emit(`Model.${collectionStoreName}.loadCollection.success`);
          })
          .catch(error => {
            Events.emit(`Model.${collectionStoreName}.loadCollection.error`);
          })
          .finally(() => {
            // console.warn('finally');
            // dispatch[collectionStoreName].setLoading('loadingCollection', false);
          });
        return asyncAction;
      },
      loadItem(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('loadItem', true);
        const asyncAction = HttpApi.get(
          _getApiPath(payload, 'loadItem'),
          payload,
        );
        asyncAction
          .then(responseWithItem => {
            dispatch[collectionStoreName].setItem({
              itemIdFromPayload: payload.id,
              item: responseWithItem,
            });
            Events.emit(`Model.${collectionStoreName}.loadItem.success`);
          })
          .catch(error => {
            Events.emit(`Model.${collectionStoreName}.loadItem.error`);
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading('loadItem', false);
          });
        return asyncAction;
      },
      createItem(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('createItem', true);
        const asyncAction = HttpApi.post(
          _getApiPath(payload, 'createItem'),
          payload,
        );
        asyncAction
          .then((data: any) => {
            dispatch[collectionStoreName].addItem(data);
            dispatch[collectionStoreName].clearError();
            Events.emit(
              `Model.${collectionStoreName}.createItem.success`,
              data,
            );
            return data;
          })
          .catch(error => {
            dispatch[collectionStoreName].setError(getErrorFromResponse(error));
            Events.emit(`Model.${collectionStoreName}.createItem.error`);
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading('createItem', false);
          });
        return asyncAction;
      },
      updateItem(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('updateItem', true);
        const asyncAction = HttpApi.patch(
          _getApiPath(payload, 'updateItem'),
          payload,
        );
        asyncAction
          .then(responseWithItem => {
            dispatch[collectionStoreName].setItem({
              itemIdFromPayload: payload.id,
              item: responseWithItem,
            });
            dispatch[collectionStoreName].clearError();
            Events.emit(`Model.${collectionStoreName}.updateItem.success`);
            Events.emit(
              `Model.${collectionStoreName}.updateItem.${payload.id}.success`,
            );
          })
          .catch(error => {
            dispatch[collectionStoreName].setError(getErrorFromResponse(error));
            Events.emit(`Model.${collectionStoreName}.updateItem.error`);
            Events.emit(
              `Model.${collectionStoreName}.updateItem.${payload.id}.error`,
            );
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading('updateItem', false);
          });
        return asyncAction;
      },
      deleteItem(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('deleteItem', true);
        const asyncAction = HttpApi.delete(
          _getApiPath(payload, 'deleteItem'),
          payload,
        );
        asyncAction
          .then(data => {
            dispatch[collectionStoreName].removeItem(payload);
            dispatch[collectionStoreName].clearError();
            Events.emit(`Model.${collectionStoreName}.deleteItem.success`);
            Events.emit(
              `Model.${collectionStoreName}.deleteItem.${payload.id}.success`,
            );
          })
          .catch(error => {
            dispatch[collectionStoreName].setError(getErrorFromResponse(error));
            Events.emit(`Model.${collectionStoreName}.deleteItem.error`);
            Events.emit(
              `Model.${collectionStoreName}.deleteItem.${payload.id}.error`,
            );
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading('deleteItem', false);
          });
        return asyncAction;
      },
      customCollectionAction(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('customCollectionAction', true);
        const asyncAction = HttpApi.post(
          _getApiPath(payload, 'customCollectionAction'),
          payload,
        );
        asyncAction
          .then(data => {
            Events.emit(
              `Model.${collectionStoreName}.${payload.action}.success`,
            );
          })
          .catch(error => {
            Events.emit(`Model.${collectionStoreName}.${payload.action}.error`);
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading(
            //   'customCollectionAction',
            //   false,
            // );
          });
        return asyncAction;
      },
      customItemAction(payload: Payload) {
        // dispatch[collectionStoreName].setLoading('customItemAction', true);
        const asyncAction = HttpApi.post(
          _getApiPath(payload, 'customItemAction'),
          payload,
        );
        asyncAction
          .then(data => {
            Events.emit(
              `Model.${collectionStoreName}.item.${payload.action}.success`,
            );
          })
          .catch(error => {
            Events.emit(
              `Model.${collectionStoreName}.item.${payload.action}.error`,
            );
          })
          .finally(() => {
            // dispatch[collectionStoreName].setLoading('customItemAction', false);
          });
        return asyncAction;
      },
    }),
  };
};
