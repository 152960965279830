import React from 'react';
import { PageContainer } from 'components';
import { Changelog } from './Changelog';

export interface ChangelogPageProps {}

export const ChangelogPage: React.FC<ChangelogPageProps> = props => {
  return (
    <PageContainer>
      <div className="bg-gray-100 p-6 overflow-auto flex-grow flex flex-col space-y-6">
        <Changelog
          date={new Date('2020-12-15')}
          updates={[
            {
              name: 'Notes Search',
              items: [
                'Increased debounced time triggering search after change to 200ms (previously it was set to 100ms)',
                'F3 now always select the whole text in the search input field before focusing it to easily overwrite the whole search query',
              ],
            },
            {
              name: 'Notes View',
              items: [
                'Fixed notes urls in opened notes bar to allow opening them in a new browser tab by clicking with mouse middle button',
                'Added close icons in the opened notes bar to allow easier closing of attached notes',
                'Added "open in a new tab" icon on the icons list',
              ],
            },
            {
              name: 'General',
              items: ['Fixed translations - remove any polish usage'],
            },
          ]}
        />
        <Changelog
          date={new Date('2020-10-08')}
          updates={[
            {
              name: 'Open many notes!',
              items: [
                'Added ability to open more notes - just click on note on list with meta key enabled',
              ],
            },
          ]}
        />
        <Changelog
          date={new Date('2020-09-06')}
          updates={[
            {
              name: 'Changelog',
              items: [
                'Added bell icon in the toolbar to navigating to changelog page',
                'Added changelog page with list of updates of the app',
              ],
            },
            {
              name: 'Note View',
              items: [
                'Faster switching between notes by improving performance of note view generating',
                'Improve note header layout',
                'Added note statusbar with some basic statistics and information about note',
              ],
            },
          ]}
        />
      </div>
    </PageContainer>
  );
};
