export const defaultTheme: ThemeInterface = {
  //  spaces
  spaceSmallX: '.25rem',
  spaceSmall: '.5rem',
  space: '1rem',
  spaceLarge: '1.5rem',
  spaceLargeX: '2rem',
  //  colors
  colorPrimaryLightest: '#49DFFF',
  colorPrimaryLighter: '#00ABEE',
  colorPrimaryLight: '#08C',
  colorPrimary: '#4368c5',
  colorPrimaryDark: '#3958a5',
  colorPrimaryDarker: '#363971',
  colorPrimaryCompany: '#ffc000',
  colorSecondary: 'lighten($colorPrimary, 20%)',
  colorLightGrey: '#d6d6d6',
  colorGrey: '#ccc',
  colorDarkGrey: '#666',
  colorDirtyWhite: '#f6f6f6',
  colorWhite: '#fff',
  colorBrown: '#473300',
  colorRed: '#cf1e2f',
  colorYellow: '#ffc000',
  colorGreen: '#72ac03',
  textColor: '#222',
  backgroundHover: 'lighten($colorPrimary, 63%)',
  // typography
  // fontFamilyDefaultOld: 'Helvetica Neue", Helvetica, Arial, sans-serif',
  fontFamilyDefault:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",\n' +
    '    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",\n' +
    '    sans-serif;',
  fontFamilyMonospace: 'Consolas, Menlo, Courier New, Courier, monospace',
  fontWeightThin: '300',
  fontWeightDefault: '400',
  fontWeightBold: '600',
  fontWeightMedium: '500',
  fontSizeSmallX: '.8rem',
  fontSizeSmall: '.9rem',
  fontSizeDefault: '1rem',
  fontSizeLarge: '1.15rem',
  fontSizeLargeX: '1.3rem',
  fontSizeLargeXX: '1.6rem',
  fontSizeLargeXXX: '2rem',
  fontSizeHuge: '3rem',
  lineHeight: '1.643rem',
  letterSpacing: '0.036rem',
  //  shadows
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  boxShadowLarge:
    '0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08)',
  boxShadowLargeX:
    '0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08)',
  boxShadowInner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  boxShadowOuter: '0 0 0 3px rgba(52, 144, 220, 0.5)',
  //  other
  borderRadius: '5px',
  //  app layout
  toolbarBackgroundColor: null,
};

export interface ThemeInterface {
  //  spaces
  spaceSmallX: string;
  spaceSmall: string;
  space: string;
  spaceLarge: string;
  spaceLargeX: string;
  //  colors
  colorPrimaryLightest: string;
  colorPrimaryLighter: string;
  colorPrimaryLight: string;
  colorPrimary: string;
  colorPrimaryDark: string;
  colorPrimaryDarker: string;
  colorPrimaryCompany: string;
  colorSecondary: string;
  colorGrey: string;
  colorLightGrey: string;
  colorDarkGrey: string;
  colorDirtyWhite: string;
  colorWhite: string;
  colorBrown: string;
  colorRed: string;
  colorYellow: string;
  colorGreen: string;
  textColor: string;
  backgroundHover: string;
  // typography
  fontWeightThin: string;
  fontFamilyDefault: string;
  fontFamilyMonospace: string;
  fontWeightDefault: string;
  fontWeightBold: string;
  fontWeightMedium: string;
  fontSizeSmallX: string;
  fontSizeSmall: string;
  fontSizeDefault: string;
  fontSizeLarge: string;
  fontSizeLargeX: string;
  fontSizeLargeXX: string;
  fontSizeLargeXXX: string;
  fontSizeHuge: string;
  lineHeight: string;
  letterSpacing: string;
  //  shadows
  boxShadow: string;
  boxShadowLarge: string;
  boxShadowLargeX: string;
  boxShadowInner: string;
  boxShadowOuter: string;
  //  other
  borderRadius: string;
  //  app layout
  toolbarBackgroundColor: string | null;
}
