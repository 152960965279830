import React, { FunctionComponent, ReactNode } from 'react';

import { styled } from 'lib/ui';

export interface FormFieldContainerProps {
  width?: number | string;
}

export const FormFieldContainer = styled.div.attrs({
  className: 'FormFieldContainerContainer',
})<FormFieldContainerProps>`
  margin: ${p => p.theme.spaceSmall} 0 0 0;
  text-align: left;
  max-width: 100%;
  width: ${p => (p.width ? p.width : '100%')};
`;
