import { Icon, IconSize, Tooltip } from 'lib/ui';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { store, StoreRootState } from 'store';

export interface OpenedNotesNavProps {}

export const OpenedNotesNav: React.FC<OpenedNotesNavProps> = props => {
  const openedNotes = useSelector(
    (state: StoreRootState) => state.OpenedNotesModel.openedNotes,
  );
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const select = useRef<HTMLSelectElement | null>(null);

  return (
    <div className="bg-gray-300">
      <div className="sm:hidden">
        <select
          ref={select}
          aria-label="Selected tab"
          className="form-select block w-full"
          value={params.id}
          onChange={() => {
            if (select.current) {
              history.push(`/notes/${select.current.value}`);
            }
          }}
        >
          {openedNotes.map(openedNote => (
            <option key={`option_${openedNote.id}`} value={openedNote.id}>
              {openedNote.name}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden sm:block">
        <nav className="flex flex-wrap p-1 sm:p-2">
          {openedNotes.map((openedNote, index) => (
            <React.Fragment key={`a_${openedNote.id}`}>
              <div className="flex items-center">
                <div
                  className={`${
                    index > 0 ? 'ml-4 ' : ''
                  } inline-block px-1 py-1 text-sm max-w-xs truncate ${
                    String(openedNote.id) === params.id
                      ? 'bg-gray-200 focus:bg-gray-300'
                      : 'hover:bg-gray-100 focus:bg-gray-200'
                  }`}
                  style={{ maxWidth: '150px' }}
                >
                  <Tooltip text={openedNote.name}>
                    <a
                      href={`/notes/${openedNote.id}`}
                      className={`${
                        String(openedNote.id) === params.id
                          ? 'text-gray-800 hover:text-gray-900 focus:outline-none'
                          : 'text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800'
                      }`}
                      onClick={event => {
                        event.preventDefault();
                        if (event.metaKey) {
                          store.dispatch.OpenedNotesModel.closeNote(
                            openedNote.id,
                          );
                        } else {
                          history.push(`/notes/${openedNote.id}`);
                        }
                      }}
                    >
                      {openedNote.name}
                    </a>
                  </Tooltip>
                </div>
                <span
                  className={'ml-3 text-gray-500 cursor-pointer'}
                  onClick={event => {
                    store.dispatch.OpenedNotesModel.closeNote(openedNote.id);
                  }}
                >
                  <Icon name={'times'} size={IconSize.SmallX} />
                </span>
              </div>
            </React.Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
};
