import { message } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Loading } from 'lib/ui';
import { copyFromText } from 'services';

import { Note } from '../NoteModel';

import { marked, markedRenderer } from './marked';
import { NoteViewContainer } from './NoteViewContainer';

export interface NoteViewProps {
  children?: ReactNode;
  note: Note;
}

const PreElementMouseDownHandler = ev => {
  if (ev.which === 2) {
    copyFromText(ev.target.textContent);
    message.success(
      <>
        <p>
          Skopiowano do schowka! <br />
          <small>{ev.target.textContent.slice(0, 100)}...</small>
        </p>
      </>,
    );
  }
};

export const NoteView: FunctionComponent<NoteViewProps> = props => {
  // console.log('NoteView props', props);
  const noteViewRef = useRef(null);
  const [noteBodyAsHtml, setNoteBodyAsHtml] = useState<string>('');

  useEffect(() => {
    if (noteViewRef.current) {
      // @ts-ignore - why it is needed? there is `if` statement
      noteViewRef.current.querySelectorAll('code').forEach(preNode => {
        preNode.removeEventListener('mousedown', PreElementMouseDownHandler);
        preNode.addEventListener('mousedown', PreElementMouseDownHandler);
      });
    }
  }, [noteViewRef.current]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setNoteBodyAsHtml(
        marked(props.note.body, {
          renderer: markedRenderer,
        }),
      );
    }, 50);

    return () => clearTimeout(timerId);
  }, [props.note]);

  return (
    <div className="prose xl:prose-xl max-w-none">
      {(typeof props.note.body === 'undefined' || !noteBodyAsHtml) && (
        <Loading text={''} />
      )}

      {noteBodyAsHtml && (
        <NoteViewContainer
          ref={noteViewRef}
          dangerouslySetInnerHTML={{ __html: noteBodyAsHtml }}
        />
      )}
    </div>
  );
};
