import React, { FunctionComponent, ReactNode } from 'react';
import { Hint, Spacer, styled } from 'lib/ui';

export interface FormFieldLabelProps {
  children?: ReactNode;
  hint?: ReactNode;
}

const FormFieldLabelDefaultProps = {};

const FormFieldLabelContainer = styled.div.attrs({
  className: 'FormFieldLabelContainer',
})`
  font-size: ${p => p.theme.fontSizeSmall};
  margin-bottom: ${p => p.theme.spaceSmallX};
  color: ${p => p.theme.colorGrey};
  width: 100%;
  display: flex;
  align-items: center;
`;

const FormFieldLabel: FunctionComponent<FormFieldLabelProps> = (
  props: FormFieldLabelProps,
) => {
  return (
    <FormFieldLabelContainer>
      {props.children}
      {props.hint && (
        <>
          <Spacer size="small" horizontal />
          <Hint>{props.hint}</Hint>
        </>
      )}
    </FormFieldLabelContainer>
  );
};

FormFieldLabel.defaultProps = FormFieldLabelDefaultProps;

export { FormFieldLabel };
