import { message } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { styled, keyframes } from 'lib/ui';

export interface NotekProps {
  children?: ReactNode;
}

const thoughts = [
  'How are you today?',
  'Hello!',
  'I like fou. Do you?',
  'I like natur. Do you?',
  'May your coffee be strong and your Monday be short',
  // https://www.momjunction.com/articles/simple-thought-of-the-day-quotes-for-kids_00438812/
  'I think I can. I know I can',
  '“With the new day come new thoughts and new strengths.” – Eleanor Roosevelt',
  '“You’re off to great places. Today is your day.” – Dr. Seuss',
  'Treat others the way you want to be treated.',
  'Your attitude determines your direction.',
  'You will never have this day again. So make it count!',
  'We make our choices. Then our choices make us.',
  'Where there is a will, there is a way.',
  'Today is a great day to learn something new.',
  'Every day may not be good. But there is something good in every day.',
  'Yesterday is gone. Tomorrow has not come yet. We have only today. Let us begin.',
  'You only live once. But if you do it right, once is enough.',
  //  education
  'When you know better, you do better.',
  '“You learn something every day if you pay attention.” – Ray LeBlond',
  'When you talk, you are only repeating what you already know. But if you listen, you may learn something new.',
  '“Learn as much as you can while you are young, since life becomes too busy later.” – Dana Stewart Scott',
  '“Educating the mind without educating the heart is no education at all.” – Aristotle',
  '“The capacity to learn is a gift; the ability to learn is a skill; the willingness to learn is a choice.” – Brian Herbert',
  '“Education is the passport to the future, for tomorrow belongs to those who prepare for it today.” – Malcolm X',
  'Listen to everyone and learn from everyone, because nobody knows everything but everyone knows something.',
  '“Education is not the learning of facts, but the training of the mind to think! “- Albert Einstein',
  '“Education is the most powerful weapon which you can use to change the world.” – Nelson Mandela',
];

const NotekContainer = styled.div`
  height: 50px;
  cursor: pointer;
`;
export const rotateInDownRight = keyframes`
    0% {
      transform-origin: right bottom;
      transform: rotate(90deg);
      opacity: 0;
    }
    100% {
      transform-origin: right bottom;
      transform: rotate(0);
      opacity: 1;
    }
`;
export const rotateInUpRight = keyframes`
    0% {
      transform: rotate(0);
    }
    50% {
      transform: translateY(-130px) translateX(-50px) scale(1.1);
    }
    100% {
      transform: rotate(0);
    }
`;

const HandContainer = styled.circle<{ initialized: boolean }>`
  animation-name: ${p => (p.initialized ? 'none' : rotateInDownRight)};
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: both;
  backface-visibility: hidden;
  //transition: transform 1500ms;

  ${NotekContainer}:hover & {
    animation-name: ${rotateInUpRight};
    animation-fill-mode: none;
  }
`;

const RightEye = styled.circle`
  opacity: 1;
  transition: opacity 500ms;

  ${NotekContainer}:hover & {
    opacity: 0;
  }
`;

export const Notek: FunctionComponent<NotekProps> = props => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setInitialized(true);
    }, 1000);
  }, []);

  return (
    <NotekContainer
      onClick={() => {
        const index = Math.floor(Math.random() * thoughts.length);
        const thought = thoughts[index];
        message.info(thought, 5);
      }}
    >
      <svg width="50px" height="50px" viewBox="0 0 1200 1200" version="1.1">
        <defs>
          <filter
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
            filterUnits="objectBoundingBox"
            id="filter-1"
          >
            <feGaussianBlur stdDeviation="12.0450368" in="SourceGraphic" />
          </filter>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stopColor="#151312" offset="0%" />
            <stop stopColor="#584B3F" offset="100%" />
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="notek">
            <HandContainer
              initialized={initialized}
              id="hand"
              fill="#FFD779"
              cx="195.5"
              cy="650.5"
              r="70.5"
            />
            <path
              d="M720.5,1067 C899.164117,1067 1044,1052.89697 1044,1035.5 C1044,1018.10303 899.164117,1004 720.5,1004 C541.835883,1004 397,1018.10303 397,1035.5 C397,1052.89697 541.835883,1067 720.5,1067 Z"
              id="shadow"
              fillOpacity="0.0565557065"
              fill="#000000"
              filter="url(#filter-1)"
            />
            <g id="notek" transform="translate(251.000000, 146.000000)">
              <rect
                id="body"
                fill="#FFD779"
                transform="translate(398.000000, 398.000000) rotate(-17.000000) translate(-398.000000, -398.000000) "
                x="80"
                y="80"
                width="636"
                height="636"
                rx="49"
              />
              <g
                id="face"
                transform="translate(398.500000, 444.500000) rotate(-8.000000) translate(-398.500000, -444.500000) translate(242.000000, 310.000000)"
                fill="url(#linearGradient-2)"
              >
                <RightEye
                  id="right-eye"
                  cx="96.3650574"
                  cy="29.3650574"
                  r="29.3650574"
                />
                <circle
                  id="left-eye"
                  cx="213.365057"
                  cy="29.3650574"
                  r="29.3650574"
                />
                <path
                  d="M156.5,268.441718 C242.932563,268.441718 313,226.356485 313,174.441718 C313,122.526952 261.638847,156.449642 156.5,158.574804 C51.3611526,160.699967 -3.12638804e-13,122.526952 -3.12638804e-13,174.441718 C-3.12638804e-13,226.356485 70.0674367,268.441718 156.5,268.441718 Z"
                  id="mouth"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </NotekContainer>
  );
};
