import { Button, ButtonVariant, Group, Spacer, styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const WelcomeImage = styled.img.attrs({ className: 'WelcomeImage' })`
  width: 200px;
  margin: ${p => p.theme.space} auto;
`;

export interface WelcomePageProps {}

const WelcomePageContainer = styled.div.attrs({
  className: 'WelcomePageContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
`;

const WelcomePageFooter = styled.div.attrs({
  className: 'WelcomePageFooter',
})`
  color: ${p => p.theme.colorGrey};
`;

export const WelcomePage: FunctionComponent<WelcomePageProps> = props => {
  return (
    <WelcomePageContainer>
      <Group align="center" vertical>
        <WelcomeImage src={`${process.env.PUBLIC_URL}/img/notek-home.svg`} />
        <Spacer />
        <h1>Notsy</h1>
        <Spacer />
        <Group align="center">
          <Button variant={ButtonVariant.Primary}>
            <Link to="/login">Login</Link>
          </Button>
        </Group>
        <Spacer />
        <WelcomePageFooter>
          &copy; F17. only for internal use.
        </WelcomePageFooter>
      </Group>
    </WelcomePageContainer>
  );
};
