import { styled } from '../';

export const UiContainerForScrollable = styled.div.attrs({
  className: 'UiContainerForScrollable',
})<{ height?: string }>`
  overflow: hidden;
  height: ${p => (p.height ? p.height : '100%')};
  display: flex;
  flex-direction: column;
`;

export const UiScrollableListMeta = styled.div.attrs({
  className: 'UiScrollableListMeta',
})`
  flex-shrink: 0;
`;

export const UiScrollableList = styled.div.attrs({
  className: 'UiScrollableList',
})<{ withoutRightPadding?: boolean }>`
  overflow: auto;
  padding-right: ${p => (p.withoutRightPadding ? 0 : p.theme.space)};

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;
