import { IdName } from 'src/modules/IdName';
import { Note } from 'src/modules/Notes';

interface OpenedNotesState {
  openedNotes: IdName[];
}

export const OpenedNotesModel = {
  state: {
    openedNotes: [],
  },
  reducers: {
    openNote: (state: OpenedNotesState, note: Note) => {
      const openedNotes = [...state.openedNotes];
      if (!openedNotes.find(openedNote => openedNote.id === note.id)) {
        openedNotes.push({ id: note.id, name: note.name });
      }

      return {
        ...state,
        openedNotes,
      };
    },
    closeNote: (state: OpenedNotesState, noteId: number) => {
      return {
        ...state,
        openedNotes: state.openedNotes.filter(
          openedNote => openedNote.id !== noteId,
        ),
      };
    },
  },
};
