import { Drawer as DrawerAntd, PageHeader } from 'antd';
import { DrawerProps as DrawerAntdProps } from 'antd/lib/drawer';
import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';

import { useKeyPressDown } from '../../../hooks';
import { Divider, Icon, IconSize, styled } from '../../';

export interface DrawerProps extends DrawerAntdProps {
  children?: ReactNode;
  onClose: () => void;
  visible: boolean;
  closeOnEsc?: boolean;
  displayCloseButton?: boolean;
  mask?: boolean;
  title?: string;
  subTitle?: string;
  align?: string;
  width?: number | string;
}

const DrawerContent = styled('div').attrs({ className: 'DrawerContent' })<{
  align?: string;
}>`
  text-align: ${p => (p.align ? p.align : 'left')};
  overflow: auto;
  padding-right: ${p => p.theme.space};
  position: relative;
`;

const DrawerInner = styled('div').attrs({ className: 'DrawerInner' })`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Drawer: FunctionComponent<DrawerProps> = ({
  visible,
  onClose,
  title,
  subTitle,
  children,
  align,
  width,
  closeOnEsc,
  displayCloseButton,
  mask,
  placement,
  ...rest
}) => {
  useKeyPressDown(
    'Escape',
    () => {
      if (visible && closeOnEsc) {
        onClose();
      }
    },
    {},
    [visible, closeOnEsc, onClose],
  );

  //  ref for drawer content
  const drawerContentRef = useRef(null);
  useEffect(() => {
    // todo - move it to service
    // @ts-ignore
    window.drawerContentRef = drawerContentRef.current;
  }, [drawerContentRef.current]);

  const displayPageHeader = title || subTitle || displayCloseButton;

  return (
    <DrawerAntd
      width={width}
      placement={placement || 'right'}
      visible={visible}
      mask={mask}
      height={'100%'}
      bodyStyle={{
        overflow: 'hidden',
        height: '100%',
      }}
      onClose={onClose}
      {...rest}
    >
      <DrawerInner>
        {displayPageHeader && (
          <>
            <PageHeader
              onBack={() => onClose()}
              backIcon={
                displayCloseButton ? (
                  <Icon name={'times'} size={IconSize.Small} />
                ) : null
              }
              title={title}
              subTitle={subTitle}
              style={{
                textAlign: 'left',
                padding: 0,
              }}
            />
            <Divider />
          </>
        )}
        <DrawerContent align={align} ref={drawerContentRef}>
          {children}
        </DrawerContent>
      </DrawerInner>
    </DrawerAntd>
  );
};

const DrawerDefaultProps = {
  align: 'left',
  left: () => {},
  visible: false,
  closeOnEsc: true,
  displayCloseButton: true,
  mask: true,
  width: 750,
};

Drawer.defaultProps = DrawerDefaultProps;

export { Drawer };
