import { styled } from 'lib/ui';

export const LogoContainer = styled.div`
  width: auto;
  height: 50px;
  margin: 0 ${p => p.theme.spaceSmall};
`;

export const LogoImage = styled.img`
  max-width: 100%;
  height: 42px;
  margin: 4px 0;
  display: block;
`;

export const ToolbarContent = styled.div`
  color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
