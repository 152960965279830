import React, { FunctionComponent, useState } from 'react';

import { SearchInput } from '../../forms';
import {
  Button,
  ButtonVariant,
  Group,
  Icon,
  Modal,
  IconSize,
  Divider,
  EmptyState,
} from '..';
import {
  ItemContainer,
  ItemIconContainer,
  ItemName,
  SimplePickerTitle,
} from './SimplePickerDialog.styled';
import {
  UiContainerForScrollable,
  UiScrollableList,
  UiScrollableListMeta,
} from '../helpers';

export interface SimplePickerDialogProps {
  onCancel: () => void;
  onSave: Function;
  onNewItem?: Function;
  items: Array<{
    id: number | string;
    name: string;
  }>;
  multiple?: boolean;
  selectedIds?: Array<number | string>;
  title?: string;
  cancelText?: string;
  okText?: string;
  renderItem?: Function;
}

const SimplePickerDialogDefaultProps = {
  selectedIds: [],
  multiple: true,
};

const SimplePickerDialog: FunctionComponent<SimplePickerDialogProps> = props => {
  //  loading state for save button
  const [loading, setLoading] = useState(false);
  //  selected items
  const [selected, setSelected] = useState(props.selectedIds || []);
  //  search query
  const [query, setQuery] = useState('');

  //  selected helpers
  const isSelected = item => {
    return selected.includes(item.id);
  };
  const toggleSelected = item => {
    if (isSelected(item)) {
      setSelected(selected.filter(id => id !== item.id));
    } else {
      setSelected([...selected, item.id]);
    }
  };

  const filteredItems = props.items.filter(
    item => !query || item.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
  );

  const isFilteredListEmpty =
    props.items.length > 0 && query.length > 0 && !filteredItems.length;
  const hasFilteredListExactName =
    query &&
    filteredItems.length > 0 &&
    !!filteredItems.find(
      item => item.name.toLowerCase() === query.toLowerCase(),
    );

  return (
    <Modal
      visible
      header={
        <SimplePickerTitle>
          <span>{props.title || 'Pick'}</span>
          {props.multiple && (
            <strong>
              {selected.length} / {props.items.length}
            </strong>
          )}
        </SimplePickerTitle>
      }
      onClose={props.onCancel}
      position="top"
      footer={
        <Group align="right">
          <Button
            disabled={loading}
            loading={!props.multiple && loading}
            onClick={props.onCancel}
            label={props.cancelText || 'Cancel'}
          />
          {props.multiple && (
            <Button
              variant={ButtonVariant.Primary}
              loading={loading}
              onClick={() => {
                setLoading(true);
                props.onSave(
                  selected,
                  props.items.filter(isSelected),
                  setLoading,
                );
              }}
              label={props.okText || 'Ok'}
            />
          )}
        </Group>
      }
    >
      <UiContainerForScrollable height={'400px'}>
        <UiScrollableListMeta>
          <SearchInput value={query} onChange={setQuery} debounceTime={150} />
          <Divider />
        </UiScrollableListMeta>
        <UiScrollableList>
          {filteredItems.map(item => (
            <ItemContainer
              key={item.id}
              onClick={() => {
                if (loading) {
                  return;
                }
                if (props.multiple) {
                  toggleSelected(item);
                } else {
                  setLoading(true);
                  props.onSave(item.id, item, setLoading);
                }
              }}
            >
              <ItemIconContainer>
                {isSelected(item) && (
                  <Icon name="check" size={IconSize.Default} />
                )}
              </ItemIconContainer>
              {props.renderItem ? (
                props.renderItem(item)
              ) : (
                <ItemName selected={isSelected(item)}>{item.name}</ItemName>
              )}
            </ItemContainer>
          ))}

          {props.items.length === 0 && (
            <EmptyState simple withIcon={false} message="Brak elementów" />
          )}

          {isFilteredListEmpty && (
            <EmptyState simple withIcon={false} message="Brak wyników" />
          )}

          {(isFilteredListEmpty ||
            (!hasFilteredListExactName && query.length > 0) ||
            (props.items.length === 0 && query.length > 0)) &&
            props.onNewItem && (
              <Button
                onClick={() => {
                  setLoading(true);
                  if (props.onNewItem) {
                    props.onNewItem(query, setLoading);
                  }
                }}
              >
                Dodaj <strong>{query}</strong>
              </Button>
            )}
        </UiScrollableList>
      </UiContainerForScrollable>
    </Modal>
  );
};

SimplePickerDialog.defaultProps = SimplePickerDialogDefaultProps;

export { SimplePickerDialog };
