import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import useToggle from 'react-use/lib/useToggle';
import { compose, withProps } from 'recompose';
import { path } from 'rambdax';

import { store } from 'store';
import { PageContainer, PageContent } from 'components';
import { NoteView, NoteEdit } from 'modules/Notes';
import { useKeyPressDown } from 'lib/hooks';
import { Loading } from 'lib/ui';
import { OpenedNotesNav } from './OpenedNotesNav';
import { NoteStatusbar } from './NoteStatusbar';
import { NotePageMeta } from './NotePageMeta';

const enhance = compose(
  connect(
    ({ NoteModel, TagModel, UiModel, loading }) => ({
      NoteModelStore: NoteModel,
      loading,
      TagModelStore: TagModel,
      UiModelStore: UiModel,
    }),
    ({ NoteModel, TagModel, UiModel }) => ({
      NoteModel,
      TagModel,
      UiModel,
    }),
  ),
  withProps(props => {
    // console.log('withProps props', props);
    return {
      ...props,
      noteId: path('match.params.id', props),
    };
  }),
);

const NotePage = enhance(props => {
  const history = useHistory();

  //  load note on id change
  useEffect(() => {
    store.dispatch.NoteModel.loadItem({ id: props.noteId }).catch(() => {
      history.push('/404');
    });
  }, [props.noteId, history]);

  //  edit mode
  const [isEditMode, toggleEditMode] = useToggle(false);
  const previousContext = useRef(props.UiModelStore.appContext);

  useEffect(() => {
    props.UiModel.setAppContext(isEditMode ? 'note-form' : 'note-view');
    return () => {
      props.UiModel.setAppContext(previousContext);
    };
  }, [isEditMode]);

  //  update note on refresh from store
  //  todo: what about unsaved changes?
  // useEffect(() => {
  //   const note = props.NoteModelStore.dataById[props.noteId];
  //   // console.warn('useEffect note', note);
  //   if (note) {
  //     setBody(getValueFromNote(note.body));
  //   }
  // }, [props.noteId, props.NoteModelStore.dataById]);

  //  edit mode on F2
  useKeyPressDown(
    'F2',
    event => {
      toggleEditMode(true);
    },
    {
      preventDefault: false,
      stopPropagation: false,
      disabled: isEditMode,
    },
    [isEditMode],
  );

  const note = props.NoteModelStore.dataById[props.noteId];

  return (
    <PageContainer>
      {!note && <Loading />}
      {note && (
        <>
          <OpenedNotesNav />
          <NotePageMeta
            note={note}
            isEditMode={isEditMode}
            onEdit={() => {
              toggleEditMode(true);
            }}
            onShare={() => {
              const hide = message.loading('Generating share url...');
              store.dispatch.NoteModel.updateItem({
                id: note.id,
                data: {
                  isPublic: true,
                },
              })
                .then(() => {
                  message.success('Note sharing enabled!');
                })
                .catch(() => {
                  message.error('Something went wrong');
                })
                .finally(() => {
                  hide();
                });
            }}
            onShareDisable={() => {
              const hide = message.loading('Disabling sharing...');
              store.dispatch.NoteModel.updateItem({
                id: note.id,
                data: {
                  isPublic: false,
                },
              })
                .then(() => {
                  message.success('Note sharing disabled!');
                })
                .catch(() => {
                  message.error('Something went wrong');
                })
                .finally(() => {
                  hide();
                });
            }}
            onDelete={() => {
              const hide = message.loading('Deleting note');
              props.NoteModel.deleteItem({
                id: note.id,
              })
                .then(() => {
                  message.success('Note deleted!');
                  props.history.goBack();
                })
                .catch(() => {
                  message.error('Something went wrong');
                })
                .finally(() => {
                  hide();
                });
            }}
          />
          <PageContent>
            {isEditMode ? (
              <NoteEdit
                note={note}
                onUpdate={() => {
                  toggleEditMode(false);
                }}
                onCancel={() => {
                  toggleEditMode(false);
                }}
              />
            ) : (
              <NoteView note={note} />
            )}
          </PageContent>

          {!isEditMode && <NoteStatusbar note={note} />}
        </>
      )}
    </PageContainer>
  );
});

export { NotePage };
