import { store } from 'store';

import { Note } from '../NoteModel';

const SearchModificators = {
  ':': 'strict',
  '#': 'tag',
  $: 'group',
};

const removeDoubleSpaces: (query: string) => string = query =>
  query.replace(/\s{2,}/gi, ' ');

const objectNameContainsQuery: (
  object: { name: string },
  query: string,
) => boolean = (object, query) => object.name.toLowerCase().indexOf(query) >= 0;

const objectBodyContainsQuery: (
  object: { body: string },
  query: string,
) => boolean = (object, query) => object.body.toLowerCase().indexOf(query) >= 0;

const objectContainsQuery: (
  object: { name: string; body: string },
  query: string,
) => boolean = (object, query) => {
  return (
    objectBodyContainsQuery(object, query) ||
    objectNameContainsQuery(object, query)
  );
};

const noteHasTagWithName: (note: Note, query: string) => boolean = (
  note,
  query,
) => {
  const tags = store.getState().TagModel.dataById;
  return (note.tags || [])
    .map(tagId => tags[tagId])
    .find(tag => {
      return tag && objectNameContainsQuery(tag, query);
    });
};

const noteHasGroupWithName: (note: Note, query: string) => boolean = (
  note,
  query,
) => {
  const groups = store.getState().GroupModel.dataById;
  const group = groups[note.group];
  return group && objectNameContainsQuery(group, query);
};

export const NotesSearch: (notes: Note[], searchQuery: string) => Note[] = (
  notes,
  searchQuery,
) => {
  let query = removeDoubleSpaces(searchQuery.trim());
  query = query.toLowerCase();
  const queries = query.split(' ');
  let filteredNotes = [...notes];

  if (!queries.length) {
    return filteredNotes;
  }

  queries.forEach(query => {
    filteredNotes = filteredNotes.filter(note => {
      const queryFirstChar = query[0];
      const modificator = SearchModificators[queryFirstChar];
      if (modificator === 'strict') {
        return objectNameContainsQuery(note, query.slice(1));
      }
      if (modificator === 'tag') {
        return noteHasTagWithName(note, query.slice(1));
      }
      if (modificator === 'group') {
        return noteHasGroupWithName(note, query.slice(1));
      }
      return objectContainsQuery(note, query);
    });
  });

  return filteredNotes;
};
