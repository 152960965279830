import React from 'react';

import { Box } from 'lib/ui';

const Page404 = () => (
  <Box noShadow>
    <h2>Ups. Pudło.</h2>
    <p>Strona, której szukasz nie została znaleziona.</p>
    <p>
      <ul>
        <li>
          <a href="/">Strona główa</a>
        </li>
        <li>
          <a href="/login">Logowanie</a>
        </li>
      </ul>
    </p>
  </Box>
);

export { Page404 };
