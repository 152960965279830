import React from 'react';

import * as Sentry from '@sentry/browser';

/**
 * @Example
 *
 *
 const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
 Sentry.init({
  dsn: sentryDsn,
  maxBreadcrumbs: 10,
  debug: !config.isProduction,
  environment: config.isProduction ? 'production' : 'dev',
  enabled: process.env.NODE_ENV === 'production',
});

 <SentryErrorBoundary enabled={process.env.NODE_ENV === 'production'}>
 {... app code}
 </SentryErrorBoundary>
 */

interface SentryErrorBoundaryProps {
  enabled: boolean;
}

interface SentryErrorBoundaryState {
  hasError: boolean;
  eventId: string | undefined;
}

export class SentryErrorBoundary extends React.Component<
  SentryErrorBoundaryProps,
  SentryErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { eventId: undefined, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (this.props.enabled) {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError && this.props.enabled) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          {this.state.eventId && (
            <p>
              <button
                type="button"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Report feedback
              </button>
            </p>
          )}
          <p>
            <a href="/">Home Page</a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
